import React, {Fragment, useContext, useEffect, useState} from "react";
import {Container, Row, Col, Card, CardBody} from "reactstrap";
import {Breadcrumbs, H5} from "../../../AbstractElements";
import Request from "../../../api/httpClient";
import ProspectoTotalWidget from "./ProspectoTotalWidget";
import ProspectoInfoWidget from "./ProspectoInfoWidget";
import HeaderCard from "../../Common/Component/HeaderCard";
import Chart from "react-apexcharts";
import {User} from "react-feather";
import CountUp from "react-countup";
import DesarrollosContext from "../../../_helper/Desarrollos";
const request = new Request();

const Dashboard = () => {
   const coloresWidgets = [
      "primary",
      "secondary",
      "warning",
      "success",
      "secondary",
      "primary",
      "success",
      "warning",
   ];
   const [totalProspectos, setTotalProspectos] = useState([
      {
         nombre: "...",
         total: 0,
         color: "secondary",
      },
      {
         nombre: "...",
         total: 0,
         color: "secondary",
      },
      {
         nombre: "...",
         total: 0,
         color: "secondary",
      },
      {
         nombre: "...",
         total: 0,
         color: "secondary",
      },
      {
         nombre: "...",
         total: 0,
         color: "secondary",
      },
      {
         nombre: "...",
         total: 0,
         color: "secondary",
      },
   ]);

   const [fuenteProspecto, setFuenteProspecto] = useState({});
   const [totalUsuarios, setTotalUsuarios] = useState([]);
   const {desarrollos} = useContext(DesarrollosContext);
   const [desarrolloSelec, setDesarrolloSelect] = useState(0);
   const role = localStorage.getItem("Role");

   const getTotalProspectos = async () => {
      setTotalProspectos([
         {
            nombre: "...",
            total: 0,
            color: "secondary",
         },
         {
            nombre: "...",
            total: 0,
            color: "secondary",
         },
         {
            nombre: "...",
            total: 0,
            color: "secondary",
         },
         {
            nombre: "...",
            total: 0,
            color: "secondary",
         },
         {
            nombre: "...",
            total: 0,
            color: "secondary",
         },
         {
            nombre: "...",
            total: 0,
            color: "secondary",
         },
      ]);

      let data = {
         id_desarrollo: desarrolloSelec,
         id_usuario: localStorage.getItem("user_id") || 0,
      };

      const response = await request.post(
         "/reportes/get/totalProspectos",
         data
      );
      if (response && !response.error) {
         if (response && !response.empty) {
            // console.log(response);
            const tp = response.map((tProspecto, i) => ({
               nombre: tProspecto.nombre,
               total: tProspecto.cantidad,
               color: coloresWidgets[i],
               icon: "customers",
            }));
            // console.log(tp);
            setTotalProspectos(tp);
         } else {
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }
   };

   const getFuenteProspectos = async () => {
      setFuenteProspecto({});

      let data = {
         id_desarrollo: desarrolloSelec,
         id_usuario: localStorage.getItem("user_id") || 0,
      };

      const response = await request.post(
         "/reportes/get/fuenteProspectos",
         data
      );
      if (response && !response.error) {
         if (response && !response.empty) {
            //console.log(response);
            CambiarFormatoFuenteProspectos(response);
            setFuenteProspecto(response);
         } else {
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }
   };

   const getTotalUsuarios = async () => {
      const response = await request.get("/reportes/get/totalUsuarios");
      if (response && !response.error) {
         if (response && !response.empty) {
            setTotalUsuarios(response);
         } else {
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }
   };

   const CambiarFormatoFuenteProspectos = (data) => {
      const series = data.map((item) => item.cantidad);
      const labels = data.map((item) => item.origin);

      const apexPieChart = {
         series: series,
         options: {
            chart: {
               width: 380,
               type: "pie",
               toolbar: {
                  show: false,
               },
            },
            labels: labels,
            colors: [
               "#f08080",
               "#00ced1",
               "#00bfff",
               "#3cb371",
               "#dda0dd",
               "#ffa07a",
               "#B03060",
               "#20b2aa",
               "#2E8B57",
               "#6495ed",
               "#7FFF00",
               "#D2691E",
               "#dc143c",
               "#6a5acd",
               "#B22222",
               "#9370db",
               "#66cdaa",
               "#4682b4",
               "#5f9ea0",
               "#9ACD32",
               "#556B2F",
               "#6B8E23",
               "#20B2AA",
               "#66CDAA",
               "#7FFFD4",
               "#40E0D0",
               "#5F9EA0",
               "#4682B4",
               "#B0C4DE",
               "#ADD8E6",
            ],
            responsive: [
               {
                  breakpoint: 480,
                  options: {
                     chart: {
                        width: 200,
                     },
                     legend: {
                        position: "bottom",
                     },
                  },
               },
            ],
         },
      };

      setFuenteProspecto(apexPieChart);
   };

   const onDesarrolloSelected = (option) => {
      setDesarrolloSelect(option.IDdesarrollo);
   };

   useEffect(() => {
      getTotalProspectos();
      getFuenteProspectos();
   }, [desarrolloSelec]);

   useEffect(() => {
      getTotalUsuarios();
   }, []);

   return (
      <Fragment>
         <Breadcrumbs mainTitle="Dashboard" parent="Gestión" title="Inicio" />
         <Container fluid={true}>
            <Col xxl="12" md="12" className="box-col-8 grid-ed-12">
               <Row className="row">
                  <Col xxl="4" md="6" className="appointment-sec box-col-6">
                     <ProspectoInfoWidget
                        desarrollos={desarrollos}
                        onOptionSelected={onDesarrolloSelected}
                        totalProspectos={totalProspectos}
                     />
                  </Col>
                  <Col xxl="8" md="6" className="box-col-6">
                     <Row>
                        {totalProspectos.map((data, i) => (
                           <Col xs="6" md="3" key={i}>
                              <ProspectoTotalWidget data={data} />
                           </Col>
                        ))}
                     </Row>
                  </Col>
               </Row>
            </Col>
         </Container>
         <Container fluid={true}>
            <Col xxl="12" md="12" className="box-col-8 grid-ed-12">
               {role === "A" ? (
                  <Row className="row">
                     <Col sm="12" xl="6">
                        <Card>
                           <HeaderCard title="Fuentes" />
                           <CardBody className="apex-chart p-t-0">
                              {Object.keys(fuenteProspecto).length !== 0 ? (
                                 <div id="piechart">
                                    <Chart
                                       options={fuenteProspecto.options}
                                       series={fuenteProspecto.series}
                                       type="pie"
                                       width={400}
                                    />
                                 </div>
                              ) : (
                                 <div
                                    className="loader-box "
                                    style={{height: "5px"}}
                                 >
                                    <div className="loader-15" />
                                 </div>
                              )}
                           </CardBody>
                        </Card>
                     </Col>
                     <Col sm="12" xl="6">
                        <Card>
                           <CardBody>
                              {totalUsuarios.length > 0 ? (
                                 <div
                                    className="balance-profile"
                                    style={{backgroundImage: "none"}}
                                 >
                                    <span className="f-light d-block text-center">
                                       Total de Usuarios
                                    </span>
                                    <H5
                                       attrH5={{
                                          className: "mt-1 text-center m-b-30",
                                       }}
                                    >
                                       <CountUp
                                          duration={3}
                                          separator=","
                                          end={totalUsuarios.reduce(
                                             (total, usuario) =>
                                                total + usuario.cantidad,
                                             0
                                          )}
                                       />
                                    </H5>
                                    <Row>
                                       {totalUsuarios.map((item, i) => (
                                          <Col xs="6" md="4" key={i}>
                                             <div
                                                className={
                                                   "balance-item success"
                                                }
                                             >
                                                <div className="balance-icon-wrap">
                                                   <div className="balance-icon">
                                                      <User />
                                                   </div>
                                                </div>
                                                <div>
                                                   <span className="f-12 f-light">
                                                      {item.nombre}
                                                   </span>
                                                   <H5>
                                                      {" "}
                                                      <CountUp
                                                         duration={3}
                                                         separator=","
                                                         end={item.cantidad}
                                                      />
                                                   </H5>
                                                </div>
                                             </div>
                                          </Col>
                                       ))}
                                    </Row>
                                 </div>
                              ) : (
                                 <div className="loader-box">
                                    <div className="loader-15" />
                                 </div>
                              )}
                           </CardBody>
                        </Card>
                     </Col>
                  </Row>
               ) : (
                  <Row className="row">
                     <Col sm="12" xl="12">
                        <Card>
                           <HeaderCard title="Fuentes" />
                           <CardBody className="apex-chart p-t-0">
                              {Object.keys(fuenteProspecto).length !== 0 ? (
                                 <div id="piechart">
                                    <Chart
                                       options={fuenteProspecto.options}
                                       series={fuenteProspecto.series}
                                       type="pie"
                                       width={400}
                                    />
                                 </div>
                              ) : (
                                 <div
                                    className="loader-box "
                                    style={{height: "5px"}}
                                 >
                                    <div className="loader-15" />
                                 </div>
                              )}
                           </CardBody>
                        </Card>
                     </Col>
                  </Row>
               )}
            </Col>
         </Container>
      </Fragment>
   );
};

export default Dashboard;
