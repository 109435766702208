import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { H5, Btn, Image, H4 } from "../../../AbstractElements";
import {
  BetterResults,
  DailyDropdown,
  DoYouWantToGet,
  MoreDetails,
} from "../../../Constant";
import SvgIcon from "../../Common/Component/SvgIcon";
import greetingImage from "../../../assets/images/dashboard-3/prospectos.svg";
import SquareGroupUi from "../OnlineCourse/SquareGroupUi";
import DropdownCommon from "../../Common/Dropdown";
import DesarrollosDropDown from "./DesarrollosDropDown";
import CountUp from "react-countup";

const ProspectoInfoWidget = ({
  desarrollos,
  onOptionSelected,
  totalProspectos,
}) => {
  return (
    <Card>
      <CardHeader className="card-no-border">
        <div className="header-top">
          <H5 attrH5={{ className: "m-0" }}>Prospectos</H5>
          <div className="card-header-right-icon">
            {desarrollos.length > 0 ? (
              <DesarrollosDropDown
                icon={false}
                options={desarrollos}
                btn={{ caret: true }}
                onOptionSelected={onOptionSelected}
              />
            ) : (
              <div className="loader-box" style={{ height: "5px" }}>
                <div className="loader-15" />
              </div>
            )}
          </div>
        </div>
      </CardHeader>
      <CardBody className="pt-0">
        <Row>
          {" "}
          <Col xl="8">
            <div className="get-image text-center">
              <Image
                attrImage={{
                  className: "img-fluid",
                  src: greetingImage,
                  alt: "leptop with men vector",
                }}
              />
            </div>
          </Col>
          <Col xl="4">
            <span className="f-light">Total</span>
            <div className="d-flex align-items-end gap-1">
              <H4>
                <CountUp
                  duration={3}
                  separator=","
                  end={totalProspectos.reduce(
                    (total, prospecto) => total + prospecto.total,
                    0
                  )}
                />
              </H4>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ProspectoInfoWidget;
