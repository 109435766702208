import React, {Fragment, useContext, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Input,
} from "reactstrap";
import Swal from "sweetalert2";
import {Breadcrumbs, Btn, H6} from "../../AbstractElements";
import Request from "../../api/httpClient";
import HeaderCard from "../Common/Component/HeaderCard";
import {Editor} from "@tinymce/tinymce-react";
import DesarrollosContext from "../../_helper/Desarrollos";

const request = new Request();

const DesarrollosPage = () => {
  const {desarrollos} = useContext(DesarrollosContext);
  const [asunto_desarrollo, setAsuntoDesarrollo] = useState("");
  const [cuerpo_desarrollo, setCuerpoDesarrollo] = useState("");
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);
  const [loadingAction, setLoadingAction] = useState(false);

  useEffect(() => {
    if (desarrollos.length > 0) {
      setSelectedButtonIndex(0);
      handleGetAction(desarrollos[0]);
    }
  }, [desarrollos]);

  const handleEliminarDesarrollo = (row) => {
    Swal.fire({
      title: "Está seguro de eliminar este desarrollo?",
      text: "Se eliminará el desarrollo: " + row.nomDesarrollo,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {
          id_desarrollo: row.IDdesarrollo,
        };

        const response = await request.post(
          "/desarrollos/desarrollo/delete",
          data
        );
        if (response && !response.error) {
          Swal.fire(
            "Eliminado!",
            "Este desarrollo ha sido eliminado",
            "success"
          );
        } else {
          toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
        }
      }
    });
  };

  const handleGetAction = async (row) => {
    setAsuntoDesarrollo("");
    setCuerpoDesarrollo("");
    setLoadingAction(true);

    let data = {
      id_desarrollo: row.IDdesarrollo,
    };
    const response = await request.post(
      "/desarrollos/desarrollo/action/get",
      data
    );
    if (response && !response.error) {
      if (response && !response.empty) {
        setAsuntoDesarrollo(response[0].asunto);
        setCuerpoDesarrollo(response[0].cuerpo);
      } else {
        setAsuntoDesarrollo("");
        setCuerpoDesarrollo("");
        setLoadingAction(false);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
    setLoadingAction(false);
  };

  //guardar los cambios en la accion del desarrollo
  const handleUpdateAction = async () => {
    let data = {
      id_desarrollo: desarrollos[selectedButtonIndex].IDdesarrollo,
      asunto: asunto_desarrollo,
      cuerpo: cuerpo_desarrollo,
    };

    const response = await request.post(
      "/desarrollos/desarrollo/action/update",
      data
    );
    if (response && !response.error) {
      if (response && !response.empty) {
        toast.success("Se guardaron los cambios", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      } else {
        toast.error(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      }
    } else {
      toast.error(response.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    }
  };

  return (
    <Fragment>
      <Breadcrumbs
        parent="Gestión"
        title="Desarrollos"
        mainTitle="Desarrollos"
      />
      <Container fluid={true}>
        <Row>
          <Col xl="4" md="6 box-col-6">
            <Card>
              <HeaderCard title="Desarrollos" />
              <CardBody>
                {desarrollos.map((des, index) => (
                  <FormGroup className="row">
                    <Col xs="7" md="7">
                      <Input value={des.nomDesarrollo} id={index} type="text" />
                    </Col>
                    <Col xs="5" md="5">
                      <Btn
                        attrBtn={{
                          color: "primary",
                          style: {
                            padding: "5px 10px",
                            marginTop: "3px",
                          },
                          onClick: () => {
                            handleGetAction(des);
                            setSelectedButtonIndex(index);
                          },
                        }}
                      >
                        <i
                          className={`fa ${
                            selectedButtonIndex === index
                              ? "fa-envelope-open"
                              : "fa-envelope"
                          }`}
                        ></i>
                      </Btn>
                      <Btn
                        attrBtn={{
                          color: "secondary",
                          style: {
                            padding: "5px 10px",
                            marginTop: "3px",
                            marginLeft: "5px",
                          },
                          onClick: () => {
                            handleEliminarDesarrollo(des);
                          },
                        }}
                      >
                        <i className="fa fa-trash"></i>
                      </Btn>
                    </Col>
                  </FormGroup>
                ))}
              </CardBody>
            </Card>
          </Col>
          <Col xl="8" className="box-col-6">
            <Card>
              <HeaderCard title="Plantilla de correo" />
              {desarrollos.length > 0 && !loadingAction ? (
                <CardBody>
                  <FormGroup>
                    <H6 attrH6={{className: "text-muted"}}>
                      Asunto del desarrollo
                    </H6>
                    <Input
                      type="text"
                      value={asunto_desarrollo}
                      onChange={(e) => {
                        setAsuntoDesarrollo(e.target.value);
                      }}
                    />
                  </FormGroup>
                  <Editor
                    value={cuerpo_desarrollo}
                    onEditorChange={(cuerpo) => {
                      setCuerpoDesarrollo(cuerpo);
                    }}
                    apiKey="sphdt0imj43tqwqei1iuvhsj10gqi6ml01jzbzp8ql746gec"
                    init={{
                      plugins:
                        "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount ",
                      toolbar:
                        "undo redo | blocks fontfamily fontsize | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | code | pagebreak underline strikethrough | fontselect fontsizeselect | checklist | forecolor casechange permanentpen formatpainter removeformat  | charmap emoticons | fullscreen  preview save print | insertfile media pageembed template anchor codesample | showcomments addcomment",
                    }}
                  />{" "}
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Btn
                      attrBtn={{
                        color: "success",
                        style: {
                          padding: "5px 10px",
                          marginTop: "20px",
                          width: "auto",
                        },
                        onClick: () => {
                          handleUpdateAction();
                        },
                      }}
                    >
                      Guardar cambios
                    </Btn>
                  </Row>
                </CardBody>
              ) : (
                <CardBody>
                  <div className="loader-box">
                    <div className="loader-15" />
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default DesarrollosPage;
