import Default from "../Components/Dashboard/Default";
import Grid from "../Components/UiKits/Grid";
import TagAndPills from "../Components/UiKits/TagAndPills";
import Progressbars from "../Components/UiKits/Progress";
import Modals from "../Components/UiKits/Modals";
import Alert from "../Components/UiKits/Alert";
import Popover from "../Components/UiKits/Popover";
import Tooltips from "../Components/UiKits/Tooltip";
import Spinners from "../Components/UiKits/Spinners";
import Dropdowns from "../Components/UiKits/Dropdown";
import Accordian from "../Components/UiKits/Accordian";
import TabBootstrap from "../Components/UiKits/Tabs/BootstrapTab";
import TabLine from "../Components/UiKits/Tabs/LineTab";
import Navs from "../Components/UiKits/Navs";
import Shadow from "../Components/UiKits/Shadows";
import List from "../Components/UiKits/Lists";
import Scrollable from "../Components/Bonus-Ui/Scrollable";
import TreeView from "../Components/Bonus-Ui/TreeView";
import BootstrapNotify from "../Components/Bonus-Ui/BootstrapNotify";
import RatingClass from "../Components/Bonus-Ui/Rating";
import Dropzones from "../Components/Bonus-Ui/Dropzones";
import Tours from "../Components/Bonus-Ui/Tour";
import SweetAlerts from "../Components/Bonus-Ui/SweetAlert";
import Carousels from "../Components/Bonus-Ui/Carousel";
import Ribbons from "../Components/Bonus-Ui/Ribbons";
import Paginations from "../Components/Bonus-Ui/Pagination";
import Steps from "../Components/Bonus-Ui/Steps";
import BreadcrumbClass from "../Components/Bonus-Ui/Breadcrumb";
import RangeSlider from "../Components/Bonus-Ui/RangeSlider";
import Imagecropper from "../Components/Bonus-Ui/ImageCropper";
import Sticky from "../Components/Bonus-Ui/Sticky";
import DragAndDrop from "../Components/Bonus-Ui/DragAndDrop";
import UploadImage from "../Components/Bonus-Ui/ImageUpload";
import BasicCards from "../Components/Bonus-Ui/Cards/BasicCards/index";
import CreativeCards from "../Components/Bonus-Ui/Cards/CreativeCards";
import TabCard from "../Components/Bonus-Ui/Cards/TabCard";
import DraggingCards from "../Components/Bonus-Ui/Cards/DragCards/index";
import Timeline1 from "../Components/Bonus-Ui/Timeline/Timeline1";
import WeatherIcon from "../Components/Icons/WeatherIcon";
import FeatherIcon from "../Components/Icons/FeatherIcon";
import ThemifyIcon from "../Components/Icons/ThemifyIcon";
import IcoIcon from "../Components/Icons/IcoIcon";
import FontawesomeIcon from "../Components/Icons/FontawesomeIcon";
import FlagIcon from "../Components/Icons/FlagIcon";
import DefaultButton from "../Components/Buttons/Default";
import FlatButton from "../Components/Buttons/FlatBtn/index";
import EdgeButton from "../Components/Buttons/Edge/index";
import RaisedButton from "../Components/Buttons/RaisedBtn/index";
import GroupButton from "../Components/Buttons/GroupBtn/index";
import ApexCharts from "../Components/Charts/ApexCharts";
import Chartist from "../Components/Charts/ChartistCharts";
import ChartJs from "../Components/Charts/ChartsJs";
import GoogleCharts from "../Components/Charts/GoogleCharts";
import FormValidation from "../Components/Forms/FormControl/FormValidation";
import BaseInput from "../Components/Forms/FormControl/BaseInput";
import CheckboxandRadio from "../Components/Forms/FormControl/CheckRadio";
import InputGroups from "../Components/Forms/FormControl/InputGroups";
import MegaOption from "../Components/Forms/FormControl/MegaOption";
import Datepicker from "../Components/Forms/FormWidget/DatePicker";
import Timepicker from "../Components/Forms/FormWidget/TimepickerComponent/TimePicker";
import TypeaheadComp from "../Components/Forms/FormWidget/TypeaheadComponent/Typeahead";
import FormDefault from "../Components/Forms/FormLayout/Default";
import FormWizard from "../Components/Forms/FormLayout/FormWizard1/FormWizard1";
import BasicTables from "../Components/Tables/Reactstrap/BasicTable";
import DataTables from "../Components/Tables/DataTable";
import Newproject from "../Components/Application/Project/Newproject";
import Project from "../Components/Application/Project/Project";
import Chat from "../Components/Application/Chat/ChatApp";
import VideoChat from "../Components/Application/Chat/VideoChat";
import Contact from "../Components/Application/Contact/ContactApp";
import Task from "../Components/Application/Task";
import TodoContain from "../Components/Application/Todo";
import ImageGallery from "../Components/Gallery/ImageGallery";
import MesonryDesc from "../Components/Gallery/MesonryDesc";
import MasonryGallery from "../Components/Gallery/MesonryGallery";
import ImageWithDesc from "../Components/Gallery/ImageWithDesc";
import ImageHover from "../Components/Gallery/ImageHover";
import BlogDetail from "../Components/Blog/BlogDetail";
import BlogPost from "../Components/Blog/BlogPost";
import BlogSingle from "../Components/Blog/BlogSingle";
import SamplePage from "../Components/Pages/Sample";
import BookmarksContain from "../Components/Application/BookMarks";
import UsersCardssContain from "../Components/Application/Users/UsersCards";
import UsersEditContain from "../Components/Application/Users/UsersEdit";
import UsersProfileContain from "../Components/Application/Users/UsersProfile";
import SocialAppContain from "../Components/Application/SocialApp";
import MailInboxContain from "../Components/Application/Email/MailInbox";
import FileManagerContain from "../Components/Application/FileManager";
import SearchResultContain from "../Components/Application/SearchResult";
import Products from "../Components/Application/Ecommerce/Products";
import ProductCart from "../Components/Application/Ecommerce/ProductCart";
import WishList from "../Components/Application/Ecommerce/Wishlist";
import CheckOut from "../Components/Application/Ecommerce/CheckOut";
import Invoice from "../Components/Application/Ecommerce/Invoice";
import OrderHistory from "../Components/Application/Ecommerce/OrderHistory";
import ProductPage from "../Components/Application/Ecommerce/ProductPage";
import PricingMemberShip from "../Components/Application/Ecommerce/PricingMemberShip";
import PaymentDetails from "../Components/Application/Ecommerce/PaymentDetails";
import ProductListContain from "../Components/Application/Ecommerce/ProductList";
import KanbanBoardContain from "../Components/Application/KanbanBoard";
import DraggableContain from "../Components/Application/Calender/DraggableCalender";
import JobCards from "../Components/JobSearch/JobCardView";
import ListView from "../Components/JobSearch/ListView";
import JobDetails from "../Components/JobSearch/JobDetail";
import Apply from "../Components/JobSearch/Apply";
import LearningList from "../Components/Learning/LearningList";
import DetailCourse from "../Components/Learning/DetailCourse";
import GoogleMaps from "../Components/Map/GoogleMap";
import PigeonMap from "../Components/Map/Pigeon Map";
import CkEditorContain from "../Components/Editor/CkEditor";
import MdeEDitorContain from "../Components/Editor/MDEEditor/MDEEditor";
import ACEEditorContain from "../Components/Editor/AceCodeEditor/ACECodeEditor";
import KnowledgeBaseContain from "../Components/Knowledgebase/KnowledgeBase";
import KnowledgeCategoryContain from "../Components/Knowledgebase/KnowledgeCategory";
import KnowledgeDetailContain from "../Components/Knowledgebase/KnowledgeDetails";
import SupportTickitContain from "../Components/SupportTicket";
import DateTimeContain from "../Components/Forms/FormWidget/DateandTime";
import FormDateRangepickerContain from "../Components/Forms/FormWidget/FormDateRangePicker";
import FormSelectContain from "../Components/Forms/FormWidget/FormSelect2";
import FormSwitchContain from "../Components/Forms/FormWidget/FormSwitch";
import FormTouchspinContain from "../Components/Forms/FormWidget/FormTouchspin";
import FormClipboardContain from "../Components/Forms/FormWidget/FormClipboard";
import FAQContent from "../Components/FAQ";

import Error404 from "../Components/Pages/ErrorPages/ErrorPage404";
import ProspectosPage from "../Components/Prospectos/ProspectosPage";
import Brokers from "../Components/brokers/Brokers";
import DisponibilidadPage from "../Components/Disponibilidad/DisponibilidadPage";
import RecordatoriosPage from "../Components/Recordatorios/RecordatoriosPage";
import DesarrollosPage from "../Components/Desarrollos/DesarrollosPage";
import MotivosPage from "../Components/Motivos/MotivosPage";
import CanalesPage from "../Components/Canales/CanalesPage";
import EmbudosPage from "../Components/Embudos/EmbudosPage";
import EtapasPage from "../Components/Etapas/EtapasPage";
// import ReportesPage from "../Components/Reportes/ReportesPage";
import RecuperacionPage from "../Components/Recuperacion/RecuperacionPage";
import Zapier from "../Components/Zapier/Zapier";
import Users from "../Components/Usuarios/Users";
import Reports from "../Components/Reports/Reports";

export const routes = [
   {
      path: `/dashboard/default/:layout`,
      Component: <Default />,
   },
   {
      path: `/usuarios/:layout`,
      Component: <Users />,
   },
   {
      path: `/prospectos/:layout`,
      Component: <ProspectosPage />,
   },
   {
      path: `/brokers/:layout`,
      Component: <Brokers />,
   },
   {
      path: `/recordatorios/:layout`,
      Component: <RecordatoriosPage />,
   },
   {
      path: `desarrollos/:layout`,
      Component: <DesarrollosPage />,
   },
   {
      path: `desarrollos/disponibilidad/:layout`,
      Component: <DisponibilidadPage />,
   },
   {
      path: `motivos/:layout`,
      Component: <MotivosPage />,
   },
   {
      path: `canales/:layout`,
      Component: <CanalesPage />,
   },
   {
      path: `recuperacion/:layout`,
      Component: <RecuperacionPage />,
   },
   {
      path: `embudos/:layout`,
      Component: <EmbudosPage />,
   },
   {
      path: `etapas/:layout`,
      Component: <EtapasPage />,
   },
   {
      path: `reportes/:layout`,
      Component: <Reports />,
   },
   {
      path: `zapier/:layout`,
      Component: <Zapier />,
   },
   {
      path: `*`,
      Component: <Error404 />,
   },
   {
      path: `/ui-kits/grids/:layout`,
      Component: <Grid />,
   },
   {
      path: `/ui-kits/tag-pills/:layout`,
      Component: <TagAndPills />,
   },
   {
      path: `/ui-kits/progress/:layout`,
      Component: <Progressbars />,
   },
   {
      path: `/ui-kits/modal/:layout`,
      Component: <Modals />,
   },
   {
      path: `/ui-kits/alert/:layout`,
      Component: <Alert />,
   },
   {
      path: `/ui-kits/popover/:layout`,
      Component: <Popover />,
   },
   {
      path: `/ui-kits/tooltips/:layout`,
      Component: <Tooltips />,
   },
   {
      path: `/ui-kits/spinner/:layout`,
      Component: <Spinners />,
   },
   {
      path: `/ui-kits/dropdown/:layout`,
      Component: <Dropdowns />,
   },
   {
      path: `/ui-kits/accordion/:layout`,
      Component: <Accordian />,
   },
   {
      path: `/ui-kits/tabs/bootstrap/:layout`,
      Component: <TabBootstrap />,
   },
   {
      path: `/ui-kits/tabs/line/:layout`,
      Component: <TabLine />,
   },
   {
      path: `/ui-kits/navs/:layout`,
      Component: <Navs />,
   },
   {
      path: `/ui-kits/shadow/:layout`,
      Component: <Shadow />,
   },
   {
      path: `/ui-kits/list/:layout`,
      Component: <List />,
   },

   // // //Bonus Ui
   {
      path: `/bonus-ui/scrollable/:layout`,
      Component: <Scrollable />,
   },
   {
      path: `/bonus-ui/tree-view/:layout`,
      Component: <TreeView />,
   },
   {
      path: `/bonus-ui/bootstrap-notify/:layout`,
      Component: <BootstrapNotify />,
   },
   {
      path: `/bonus-ui/rating/:layout`,
      Component: <RatingClass />,
   },
   {
      path: `/bonus-ui/dropzone/:layout`,
      Component: <Dropzones />,
   },
   {
      path: `/bonus-ui/tour/:layout`,
      Component: <Tours />,
   },
   {
      path: `/bonus-ui/sweet-alert/:layout`,
      Component: <SweetAlerts />,
   },
   {
      path: `/bonus-ui/carousel/:layout`,
      Component: <Carousels />,
   },
   {
      path: `/bonus-ui/ribbons/:layout`,
      Component: <Ribbons />,
   },
   {
      path: `/bonus-ui/pagination/:layout`,
      Component: <Paginations />,
   },
   {
      path: `/bonus-ui/steps/:layout`,
      Component: <Steps />,
   },
   {
      path: `/bonus-ui/breadcrumb/:layout`,
      Component: <BreadcrumbClass />,
   },
   {
      path: `/bonus-ui/rangeslider/:layout`,
      Component: <RangeSlider />,
   },
   {
      path: `/bonus-ui/imagecropper/:layout`,
      Component: <Imagecropper />,
   },
   {
      path: `/bonus-ui/stickynotes/:layout`,
      Component: <Sticky />,
   },
   {
      path: `/bonus-ui/drag_and_drop/:layout`,
      Component: <DragAndDrop />,
   },
   {
      path: `/bonus-ui/image-upload/:layout`,
      Component: <UploadImage />,
   },
   {
      path: `/bonus-ui/card/basiccards/:layout`,
      Component: <BasicCards />,
   },
   {
      path: `/bonus-ui/card/creativecards/:layout`,
      Component: <CreativeCards />,
   },
   {
      path: `/bonus-ui/card/tabcard/:layout`,
      Component: <TabCard />,
   },
   {
      path: `/bonus-ui/card/draggingcards/:layout`,
      Component: <DraggingCards />,
   },
   {
      path: `/bonus-ui/timelines/timeline1/:layout`,
      Component: <Timeline1 />,
   },

   // // //Icons
   {
      path: `/icons/flag_icons/:layout`,
      Component: <FlagIcon />,
   },
   {
      path: `/icons/fontawesome_icon/:layout`,
      Component: <FontawesomeIcon />,
   },
   {
      path: `/icons/ico_icon/:layout`,
      Component: <IcoIcon />,
   },
   {
      path: `/icons/themify_icons/:layout`,
      Component: <ThemifyIcon />,
   },
   {
      path: `/icons/feather_icons/:layout`,
      Component: <FeatherIcon />,
   },
   {
      path: `/icons/weather_icons/:layout`,
      Component: <WeatherIcon />,
   },

   // // //Buttons
   {
      path: `/buttons/simplebutton/:layout`,
      Component: <DefaultButton />,
   },
   {
      path: `/buttons/flat/:layout`,
      Component: <FlatButton />,
   },
   {
      path: `/buttons/edge/:layout`,
      Component: <EdgeButton />,
   },
   {
      path: `/buttons/raised/:layout`,
      Component: <RaisedButton />,
   },
   {
      path: `/buttons/group/:layout`,
      Component: <GroupButton />,
   },

   // // //Charts
   {
      path: `/charts/apex/:layout`,
      Component: <ApexCharts />,
   },
   {
      path: `/charts/chartist/:layout`,
      Component: <Chartist />,
   },
   {
      path: `/charts/chartJs/:layout`,
      Component: <ChartJs />,
   },
   {
      path: `/charts/google/:layout`,
      Component: <GoogleCharts />,
   },

   // // // Forms
   {
      path: `/forms/controls/validation/:layout`,
      Component: <FormValidation />,
   },
   {
      path: `/forms/controls/input/:layout`,
      Component: <BaseInput />,
   },
   {
      path: `/forms/controls/radio-checkbox/:layout`,
      Component: <CheckboxandRadio />,
   },
   {
      path: `/forms/controls/group/:layout`,
      Component: <InputGroups />,
   },
   {
      path: `/forms/controls/megaoption/:layout`,
      Component: <MegaOption />,
   },
   {
      path: `/forms/widget/datepicker/:layout`,
      Component: <Datepicker />,
   },
   {
      path: `/forms/widget/timepicker/:layout`,
      Component: <Timepicker />,
   },
   {
      path: `/forms/widget/typeahead/:layout`,
      Component: <TypeaheadComp />,
   },
   {
      path: `/forms/widget/datetimepicker/:layout`,
      Component: <DateTimeContain />,
   },
   {
      path: `/forms/widget/rangepicker/:layout`,
      Component: <FormDateRangepickerContain />,
   },
   {
      path: `/forms/widget/select2/:layout`,
      Component: <FormSelectContain />,
   },
   {
      path: `/forms/widget/switch/:layout`,
      Component: <FormSwitchContain />,
   },
   {
      path: `/forms/widget/touchspin/:layout`,
      Component: <FormTouchspinContain />,
   },
   {
      path: `/forms/widget/clipboard/:layout`,
      Component: <FormClipboardContain />,
   },

   {
      path: `/forms/layout/formDefault/:layout`,
      Component: <FormDefault />,
   },
   {
      path: `/forms/layout/formWizard/:layout`,
      Component: <FormWizard />,
   },

   // //Tables
   {
      path: `/table/reactstraptable/basictable/:layout`,
      Component: <BasicTables />,
   },
   {
      path: `/table/datatable/:layout`,
      Component: <DataTables />,
   },

   // //Applicatiion
   {
      path: `/app/project/project-list/:layout`,
      Component: <Project />,
   },
   {
      path: `/app/project/new-project/:layout`,
      Component: <Newproject />,
   },
   {
      path: `/app/chat-app/chats/:layout`,
      Component: <Chat />,
   },
   {
      path: `/app/chat-app/chat-video-app/:layout`,
      Component: <VideoChat />,
   },
   {
      path: `/app/contact-app/contacts/:layout`,
      Component: <Contact />,
   },

   {path: `/app/task/:layout`, Component: <Task />},
   {
      path: `/app/bookmark/:layout`,
      Component: <BookmarksContain />,
   },
   {
      path: `/app/todo-app/todo/:layout`,
      Component: <TodoContain />,
   },

   {
      path: `/app/users/profile/:layout`,
      Component: <UsersProfileContain />,
   },
   {
      path: `/app/users/edit/:layout`,
      Component: <UsersEditContain />,
   },
   {
      path: `/app/users/cards/:layout`,
      Component: <UsersCardssContain />,
   },
   {
      path: `/app/social-app/:layout`,
      Component: <SocialAppContain />,
   },

   {
      path: `/app/calendar/draggable-calendar/:layout`,
      Component: <DraggableContain />,
   },

   {
      path: `/app/email-app/:layout`,
      Component: <MailInboxContain />,
   },
   {
      path: `/app/file-manager/:layout`,
      Component: <FileManagerContain />,
   },
   {
      path: `/app/search/:layout`,
      Component: <SearchResultContain />,
   },
   {
      path: `/app/ecommerce/product/:layout`,
      Component: <Products />,
   },
   {
      path: `/app/ecommerce/product-page/:layout/:id`,
      Component: <ProductPage />,
   },
   {
      path: `/app/ecommerce/payment-details/:layout`,
      Component: <PaymentDetails />,
   },
   {
      path: `/app/ecommerce/orderhistory/:layout`,
      Component: <OrderHistory />,
   },
   {
      path: `/app/ecommerce/pricing/:layout`,
      Component: <PricingMemberShip />,
   },
   {
      path: `/app/ecommerce/invoice/:layout`,
      Component: <Invoice />,
   },
   {
      path: `/app/ecommerce/cart/:layout`,
      Component: <ProductCart />,
   },
   {
      path: `/app/ecommerce/wishlist/:layout`,
      Component: <WishList />,
   },
   {
      path: `/app/ecommerce/checkout/:layout`,
      Component: <CheckOut />,
   },
   {
      path: `/app/ecommerce/product-list/:layout`,
      Component: <ProductListContain />,
   },
   {
      path: `/app/kanban-board/:layout`,
      Component: <KanbanBoardContain />,
   },

   // //Gallery
   {
      path: `/app/gallery/grids/:layout`,
      Component: <ImageGallery />,
   },
   {
      path: `/app/gallery/griddesc/:layout`,
      Component: <ImageWithDesc />,
   },
   {
      path: `/app/gallery/masonrys/:layout`,
      Component: <MasonryGallery />,
   },
   {
      path: `/app/gallery/masonrydesc/:layout`,
      Component: <MesonryDesc />,
   },
   {
      path: `/app/gallery/hover_effect/:layout`,
      Component: <ImageHover />,
   },

   // //page
   {
      path: `/pages/sample-page/:layout`,
      Component: <SamplePage />,
   },

   // //Blog
   {
      path: `/app/blog/blogdetails/:layout`,
      Component: <BlogDetail />,
   },
   {
      path: `/app/blog/blogsingle/:layout`,
      Component: <BlogSingle />,
   },
   {
      path: `/app/blog/blogpost/:layout`,
      Component: <BlogPost />,
   },

   // //Job Search
   {
      path: `/app/jobsearch/cardview/:layout`,
      Component: <JobCards />,
   },
   {
      path: `/app/jobsearch/joblist/:layout`,
      Component: <ListView />,
   },
   {
      path: `/app/jobsearch/jobdetail/:layout`,
      Component: <JobDetails />,
   },
   {
      path: `/app/jobsearch/jobapply/:layout`,
      Component: <Apply />,
   },

   // //Learning
   {
      path: `/app/learning/learninglist/:layout`,
      Component: <LearningList />,
   },
   {
      path: `/app/learning/learningdetail/:layout`,
      Component: <DetailCourse />,
   },

   // //Map
   {
      path: `/app/map/googlemap/:layout`,
      Component: <GoogleMaps />,
   },
   {
      path: `/app/map/pigeonmap/:layout`,
      Component: <PigeonMap />,
   },

   // //Editor
   {
      path: `/editor/ckeditor/:layout`,
      Component: <CkEditorContain />,
   },
   {
      path: `/editor/mdeeditor/:layout`,
      Component: <MdeEDitorContain />,
   },
   {
      path: `/editor/aceeditor/:layout`,
      Component: <ACEEditorContain />,
   },

   // //Knowledge
   {
      path: `/app/knowledgebase/:layout`,
      Component: <KnowledgeBaseContain />,
   },
   {
      path: `/app/knowledgebase-category/:layout`,
      Component: <KnowledgeCategoryContain />,
   },
   {
      path: `/app/knowledgebase-detail/:layout`,
      Component: <KnowledgeDetailContain />,
   },

   // //Support Ticket
   {
      path: `/app/supportticket/:layout`,
      Component: <SupportTickitContain />,
   },

   // //Faq
   {
      path: `/app/faq/:layout`,
      Component: <FAQContent />,
   },
];
