import DesarrollosContext from "./index";
import React, {useEffect, useState} from "react";
import Request from "../../api/httpClient";

const DesarrollosProvider = (props) => {
   const [desarrollos, setDesarrollos] = useState([]);
   const [loadingDesarrollos, setLoadingDesarrollos] = useState(true);
   const request = new Request();

   const getDesarrollos = async () => {
      //console.log("enviando...");
      const response = await request.get("/desarrollos/get/all");
      //console.log(response);
      if (response && !response.error) {
         if (response) {
            setDesarrollos(response);
         } else {
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }
      setLoadingDesarrollos(false);
   };

   useEffect(() => {
      getDesarrollos();
   }, []);

   return (
      <DesarrollosContext.Provider value={{desarrollos, loadingDesarrollos}}>
         {props.children}
      </DesarrollosContext.Provider>
   );
};

export default DesarrollosProvider;
