import React from "react";
import {Suspense, useEffect, useState} from "react";
import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import {
   configureFakeBackend,
   authHeader,
   handleResponse,
} from "../Services/fack.backend";
import Callback from "../Auth/Callback";
import Loader from "../Layout/Loader";
import {authRoutes} from "./AuthRoutes";
import LayoutRoutes from "../Route/LayoutRoutes";
import Signin from "../Auth/Signin";
import PrivateRoute from "./PrivateRoute";
import {classes} from "../Data/Layouts";

configureFakeBackend();

const verificarSesion = () => {
   var horaLogin = localStorage.getItem("horaLogin");

   if (new Date().getTime() - horaLogin > 12 * 60 * 60 * 1000) {
      localStorage.setItem("login", false);
      return false;
   } else {
      return true;
   }
};

const Routers = () => {
   const login = useState(verificarSesion())[0];
   const [authenticated, setAuthenticated] = useState(false);
   const jwt_token = localStorage.getItem("token");
   const defaultLayoutObj = classes.find(
      (item) => Object.values(item).pop(1) === "compact-wrapper"
   );
   const layout =
      localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

   useEffect(() => {
      let abortController = new AbortController();
      const requestOptions = {method: "GET", headers: authHeader()};
      fetch("/users", requestOptions).then(handleResponse);

      setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
      console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
      console.disableYellowBox = true;
      return () => {
         abortController.abort();
      };
   }, []);

   return (
      <BrowserRouter basename={"/"}>
         <Suspense fallback={<Loader />}>
            <Routes>
               <Route path={"/"} element={<PrivateRoute />}>
                  {login || authenticated || jwt_token ? (
                     <>
                        <Route
                           exact
                           path={``}
                           element={
                              <Navigate to={`/dashboard/default/${layout}`} />
                           }
                        />
                        <Route
                           exact
                           path={`/`}
                           element={
                              <Navigate to={`/dashboard/default/${layout}`} />
                           }
                        />
                     </>
                  ) : (
                     ""
                  )}
                  <Route path={`/*`} element={<LayoutRoutes />} />
               </Route>
               <Route path={`/callback`} render={() => <Callback />} />
               <Route exact path={`/login`} element={<Signin />} />
               {authRoutes.map(({path, Component}, i) => (
                  <Route path={path} element={Component} key={i} />
               ))}
            </Routes>
         </Suspense>
      </BrowserRouter>
   );
};

export default Routers;
