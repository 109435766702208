import React, {useState} from "react";
import moment from "moment";
import Select from "react-select";
import DatePicker from "react-datepicker";
import {toast} from "react-toastify";
import {Form, FormGroup, Input, Label, Row, Col, Button} from "reactstrap";
import Request from "../../../../api/httpClient";

const request = new Request();

const EMINDER = [
   {value: 0, label: "No"},
   {value: 1, label: "Si"},
];

const TracingCreate = ({reload, prospectId, handleModal}) => {
   const [isLoading, setIsLoading] = useState(false);
   const [eventDate, setEventDate] = useState(new Date());
   const [eventTime, setEventTime] = useState(new Date());
   const [subject, setSubject] = useState("");
   const [details, setDetails] = useState("");
   const [selectedReminder, setSelectedReminder] = useState(EMINDER[0]);

   const handleSubmit = async (event) => {
      event.preventDefault();
      setIsLoading(true);

      const fechaHoy = moment().format("YYYY-MM-DD");
      const registerDate = new Date();

      let data = {
         id_prospecto: prospectId,
         fechaVencimiento: moment(fechaHoy)
            .add(45, "days")
            .format("YYYY-MM-DD"),
         id_usuario: +localStorage.getItem("user_id") || 0,
         asunto: subject,
         detalles: details,
         rEvento: selectedReminder.value,
         fEvento: moment(eventDate).format("YYYY-MM-DD"),
         hEvento: moment(eventTime).format("HH:mm:ss"),
         register_date: registerDate,
      };

      const response = await request.post(
         "/prospectos/prospecto/seguimiento/create",
         data
      );

      if (response && !response.error) {
         handleModal("create");
         setIsLoading(false);
         reload("Seguimiento creado con éxito ");
      } else {
         setIsLoading(false);
         toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
         });
      }
   };

   return (
      <Form onSubmit={handleSubmit}>
         <FormGroup>
            <Row>
               <Col xl="4">
                  <Label className="col-form-label text-start">Recordar:</Label>
                  <Select
                     options={EMINDER}
                     defaultValue={selectedReminder}
                     placeholder={"Seleccionar estatus"}
                     onChange={(e) => setSelectedReminder(e)}
                     styles={{
                        menu: (provided) => ({
                           ...provided,
                           zIndex: 3,
                        }),
                     }}
                  />
               </Col>
               <Col xl="4">
                  <Label className="col-form-label text-start">
                     Fecha del evento:
                  </Label>
                  <DatePicker
                     className="form-control datetimepicker-input digits"
                     selected={eventDate}
                     onChange={(fecha) => setEventDate(fecha)}
                     dateFormat="dd/MM/yyyy"
                     required
                  />
               </Col>
               <Col xl="4">
                  <Label className="col-form-label text-start">
                     Hora del evento:
                  </Label>
                  <DatePicker
                     className="form-control datetimepicker-input digits"
                     selected={eventTime}
                     onChange={(hora) => setEventTime(hora)}
                     showTimeSelect
                     showTimeSelectOnly
                     timeIntervals={15}
                     timeCaption="Time"
                     dateFormat="h:mm aa"
                     required
                  />
               </Col>
               <Col xl="12">
                  <Label className="col-form-label text-start">Asunto:</Label>
                  <Input
                     id="asunto"
                     type="text"
                     onChange={(e) => {
                        setSubject(e.target.value);
                     }}
                     required
                  />
               </Col>
               <Col xl="12">
                  <Label className="col-form-label text-start">Detalles:</Label>
                  <Input
                     id="detalles"
                     type="textarea"
                     className="form-control"
                     rows="2"
                     onChange={(e) => {
                        setDetails(e.target.value);
                     }}
                     required
                  />
               </Col>
               <div className="w-100 d-flex justify-content-end btn-showcase m-t-10">
                  <Button
                     className="m-r-10"
                     color="secondary"
                     onClick={() => handleModal("create")}
                  >
                     Cancelar
                  </Button>
                  <Button type="submit" color="primary" disabled={isLoading}>
                     {isLoading ? "Guardando..." : "Guardar"}
                  </Button>
               </div>
            </Row>
         </FormGroup>
      </Form>
   );
};

export default TracingCreate;
