import React, {
   Fragment,
   useContext,
   useEffect,
   useLayoutEffect,
   useState,
} from "react";
import {toast} from "react-toastify";
import {
   Container,
   Row,
   Col,
   Card,
   CardBody,
   Input,
   Button,
   Label,
   Media,
   Nav,
   NavItem,
   NavLink,
} from "reactstrap";
import Swal from "sweetalert2";
import {Breadcrumbs} from "../../AbstractElements";
import Request from "../../api/httpClient";
import HeaderCard from "../Common/Component/HeaderCard";
import DesarrollosContext from "../../_helper/Desarrollos";
import MotivosContext from "../../_helper/Motivos";
import Board from "@asseinfo/react-kanban";
import "@asseinfo/react-kanban/dist/styles.css";
import {H6, P} from "../../AbstractElements";
import {Box, Grid} from "react-feather";
import "./board.css";
import moment from "moment";
import ProspectEditModal from "../Prospectos/components/Modals/ProspectEditModal";

const request = new Request();

const EtapasPage = () => {
   const [embudos, setEmbudos] = useState({
      columns: [],
      cards: [],
   });
   const [loadingEmbudo, setLoadingEmbudo] = useState(false);
   const [customboard, setCustomBoard] = useState({columns: [], cards: []});
   const [loadingCustomBoard, setLoadingCustomBoard] = useState(false);
   const {desarrollos} = useContext(DesarrollosContext);
   const [desarrolloSelec, setDesarrolloSelec] = useState({});
   const {motivos} = useContext(MotivosContext);
   const [reasonsList, setReasonsList] = useState({});
   const [statusList, setStatusList] = useState({});
   const [otrosUsuariosInfo, setOtrosUsauriosInfo] = useState(false);
   const [role] = useState(localStorage.getItem("Role"));
   const [showModal, setShowModal] = useState(false);
   const toggleEdit = () => setShowModal(!showModal);
   const [prospectos, setProspectos] = useState([]);
   const [prospect, setProspect] = useState(null);
   const [canales, setCanales] = useState([]);
   const [loadingCanales, setLoadingCanales] = useState(false);

   const getEmbudos = async () => {
      setEmbudos({columns: [], cards: []});
      setLoadingEmbudo(true);
      const response = await request.get("/embudos/get/all");
      if (response && !response.error) {
         if (response && !response.empty) {
            let etapas = {};

            response.forEach((item) => {
               etapas[item.funnelId] = item.funnelName;
            });
            setStatusList(etapas);

            const result = {
               columns: response.map((item) => ({
                  id: item.funnelId,
                  title: item.funnelName,
                  cards: [],
               })),
            };
            setEmbudos(result);
            if (result.columns.length > 0) {
               getEtapas(result);
            }
         } else {
            setEmbudos({columns: [], cards: []});
            setLoadingEmbudo(false);
            console.error(response.message);
         }
      } else {
         setEmbudos({columns: [], cards: []});
         setLoadingEmbudo(false);
         console.error(response.message);
      }
      setLoadingEmbudo(false);
   };

   const getCanales = async () => {
      setCanales([]);
      setLoadingCanales(true);
      const response = await request.get("/canales/get/all");
      if (response && !response.error) {
         if (response && !response.empty) {
            const fuentes = response.map((item) => {
               return {
                  label: item.channelName,
                  value: item.channelName,
               };
            });
            setCanales(fuentes);
         } else {
            setCanales([]);
            setLoadingCanales(false);
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }
      setLoadingCanales(false);
   };

   const getProspectos = async () => {
      setProspectos([]);
      const data = {
         id_usuario: otrosUsuariosInfo
            ? 0
            : localStorage.getItem("user_id") || 0,
         id_desarrollo: desarrolloSelec.IDdesarrollo || 0,
      };

      const response = await request.post("/prospectos/get/all", data);
      if (response && !response.error) {
         if (response && !response.empty) {
            setProspectos(response);
         } else {
            setProspectos([]);
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }
   };

   const getEtapas = async (embudos) => {
      setCustomBoard({columns: [], cards: []});
      setLoadingCustomBoard(true);
      const data = {
         id_desarrollo: desarrolloSelec.IDdesarrollo || 0,
         id_usuario: otrosUsuariosInfo
            ? 0
            : localStorage.getItem("user_id") || 0,
      };
      const response = await request.post("/prospectos/by/embudo", data);
      if (response && !response.error) {
         if (response && !response.empty) {
            let etapas = embudos;

            response.forEach((item) => {
               let column = etapas.columns.find(
                  (col) => col.id === item.funnelId
               );
               if (column) {
                  column.cards.push({
                     id: item.pkclient,
                     prospecto: item.client,
                     fecha: moment(item.registerclient).format("DD-MM-YYYY"),
                     desarrollo: item.nomDesarrollo,
                     origen: item.origin,
                     bg_color: "bg-light-info",
                     email: item.emailclient,
                     data: prospectos,
                  });
               }
            });
            setCustomBoard(etapas);
         } else {
            setCustomBoard({columns: [], cards: []});
            setLoadingCustomBoard(false);
            console.error(response.message);
         }
      } else {
         setCustomBoard({columns: [], cards: []});
         setLoadingCustomBoard(false);
         console.error(response.message);
      }
      setLoadingCustomBoard(false);
   };

   const reload = (refresh, message, id_desarrollo) => {
      if (desarrollos.length > 0) {
         const desarrolloSeleccionado = desarrollos.find(
            (desarrollo) => desarrollo.IDdesarrollo === id_desarrollo
         );
         if (desarrolloSeleccionado) {
            setDesarrolloSelec(desarrolloSeleccionado);
         }
      }
      toast.success(message, {
         position: toast.POSITION.BOTTOM_RIGHT,
         autoClose: 2000,
      });
      if (refresh) getProspectos();
   };

   const handleCardDragEnd = async (board, card, source, destination) => {
      Swal.fire({
         title: "Razón del cambio",
         text: "Puede dejar un comentario sobre el cambio de estado",
         icon: "warning",
         input: "select",
         inputOptions: reasonsList,
         inputValue: 0,
         showCancelButton: true,
         confirmButtonColor: "#3085d6",
         cancelButtonColor: "#d33",
         confirmButtonText: "Si, Cambiar",
         cancelButtonText: "Cancelar",
         reverseButtons: true,
      }).then(async (result) => {
         if (result.isConfirmed) {
            if (result.value === "otros") {
               Swal.fire({
                  title: "Ingrese su razón",
                  input: "textarea",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Aceptar",
                  cancelButtonText: "Cancelar",
                  reverseButtons: true,
               }).then(async (textResult) => {
                  if (textResult.isConfirmed) {
                     let data = {
                        id_prospecto: card.id,
                        nombre_prospecto: card.prospecto,
                        email_prospecto: card.email,
                        funnelId: destination.toColumnId,
                        reason: textResult.value,
                        id_usuario: localStorage.getItem("user_id") || 0,
                        id_desarrollo: desarrolloSelec.IDdesarrollo || 0,
                        actividad: JSON.stringify({
                           value:
                              "Se realizó un cambio de embudo de <strong>" +
                              statusList[source.fromColumnId] +
                              "</strong> a <strong>" +
                              statusList[destination.toColumnId] +
                              "</strong>.",
                           details: {
                              from: statusList[source.fromColumnId],
                              to: statusList[destination.toColumnId],
                           },
                        }),
                     };

                     const response = await request.post(
                        "/prospectos/prospecto/status",
                        data
                     );
                     if (response && !response.error) {
                        toast.success(
                           `El prospecto ${card.prospecto} se ha cambiado de embudo`,
                           {
                              position: toast.POSITION.BOTTOM_RIGHT,
                              autoClose: 2000,
                           }
                        );
                     } else {
                        toast.error(response.message, {
                           position: toast.POSITION.BOTTOM_RIGHT,
                           autoClose: 2000,
                        });
                     }
                  }
               });
            } else {
               let data = {
                  id_prospecto: card.id,
                  nombre_prospecto: card.prospecto,
                  email_prospecto: card.email,
                  funnelId: destination.toColumnId,
                  reason: result.value === "0" ? "" : reasonsList[result.value],
                  id_usuario: localStorage.getItem("user_id") || 0,
                  id_desarrollo: desarrolloSelec.IDdesarrollo || 0,
                  actividad: JSON.stringify({
                     value:
                        "Se realizó un cambio de embudo de <strong>" +
                        statusList[source.fromColumnId] +
                        "</strong> a <strong>" +
                        statusList[destination.toColumnId] +
                        "</strong>.",
                     details: {
                        from: statusList[source.fromColumnId],
                        to: statusList[destination.toColumnId],
                     },
                  }),
               };

               const response = await request.post(
                  "/prospectos/prospecto/status",
                  data
               );
               if (response && !response.error) {
                  toast.success(
                     `El prospecto ${card.prospecto} se ha cambiado de embudo`,
                     {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 2000,
                     }
                  );
               } else {
                  toast.error(response.message, {
                     position: toast.POSITION.BOTTOM_RIGHT,
                     autoClose: 2000,
                  });
               }
            }
         }
      });
   };

   const handleEPropspectEdit = (id) => {
      const selectedProspect = prospectos.find(
         (prospecto) => prospecto.pkclient === id.id
      );
      setProspect(selectedProspect);
      toggleEdit();
   };

   useEffect(() => {
      if (desarrollos.length > 0) {
         setDesarrolloSelec(desarrollos[0]);
      }
   }, [desarrollos]);

   useEffect(() => {
      if (desarrollos.length > 0 && Object.keys(desarrolloSelec).length !== 0) {
         getProspectos();
         getEmbudos();
         getCanales();
      }
   }, [desarrolloSelec, otrosUsuariosInfo]);

   useEffect(() => {
      if (motivos.length > 0) {
         let reasons = {
            0: "Sin comentario",
         };

         motivos.forEach((item) => {
            reasons[item.reasonId] = item.reasonName;
         });

         reasons["otros"] = "Otros...";

         setReasonsList(reasons);
      }
   }, [motivos]);

   useLayoutEffect(() => {
      if (customboard.columns.length > 0) {
         const columns = document.querySelectorAll(".react-kanban-column");

         let maxHeight = 0;

         columns.forEach((column) => {
            const columnHeight = column.offsetHeight;
            if (columnHeight > maxHeight) {
               maxHeight = columnHeight;
            }
         });

         columns.forEach((column) => {
            column.style.minHeight = `${maxHeight}px`;
         });
      }
   });

   return (
      <>
         <Breadcrumbs mainTitle="Etapas" parent="Gestión" title="Etapas" />
         <Container fluid={true} className="jkanban-container">
            <Row>
               <Col sm="10">
                  <HeaderCard title="Registro de prospectos" />
               </Col>

               <Col sm="2">
                  {role === "A" && (
                     <Media>
                        <Label className="col-form-label m-r-10 m-t-10">
                           Otros usuarios
                        </Label>
                        <Media
                           body
                           className="'text-end icon-state switch-outline m-t-10"
                        >
                           <Label className="switch">
                              <Input
                                 type="checkbox"
                                 onChange={(e) =>
                                    setOtrosUsauriosInfo(!otrosUsuariosInfo)
                                 }
                              />
                              <span className="switch-state bg-info"></span>
                           </Label>
                        </Media>
                     </Media>
                  )}
               </Col>
            </Row>
            <Row>
               <Col md="12" className="project-list">
                  <Card>
                     <Row>
                        <Col md="12">
                           <Nav tabs className="border-tab">
                              {desarrollos.map((des, index) => (
                                 <NavItem key={index}>
                                    <NavLink
                                       className={
                                          desarrolloSelec.nomDesarrollo ===
                                          des.nomDesarrollo
                                             ? "active"
                                             : ""
                                       }
                                       onClick={() => setDesarrolloSelec(des)}
                                    >
                                       <Box />
                                       {des.nomDesarrollo}
                                    </NavLink>
                                 </NavItem>
                              ))}
                              <NavItem>
                                 <NavLink
                                    className={
                                       desarrolloSelec.nomDesarrollo === "Todos"
                                          ? "active"
                                          : ""
                                    }
                                    onClick={() =>
                                       setDesarrolloSelec({
                                          nomDesarrollo: "Todos",
                                       })
                                    }
                                 >
                                    <Grid />
                                    Todos
                                 </NavLink>
                              </NavItem>
                           </Nav>
                        </Col>
                     </Row>
                  </Card>
               </Col>
               <Col xs="12">
                  <Fragment>
                     <Card>
                        <CardBody className="kanban-block">
                           {embudos.columns.length > 0 &&
                           !loadingCustomBoard ? (
                              <div className="kanban-container custom-board">
                                 <main className="kanban-drag">
                                    <Board
                                       initialBoard={
                                          customboard.columns.length > 0
                                             ? customboard
                                             : embudos
                                       }
                                       onCardDragEnd={handleCardDragEnd}
                                       disableColumnDrag
                                       renderCard={({
                                          prospecto,
                                          fecha,
                                          origen,
                                          desarrollo,
                                          bg_color,
                                          id,
                                       }) => (
                                          <div className="kanban-item" key={id}>
                                             <div
                                                className={`kanban-box bg-light-info`}
                                                href="#javascript"
                                             >
                                                <Row>
                                                   {" "}
                                                   <H6>{prospecto}</H6>
                                                   <Media>
                                                      <Media body>
                                                         <P>{desarrollo}</P>
                                                      </Media>
                                                   </Media>
                                                   <span className="date">
                                                      {fecha}
                                                   </span>
                                                   <small className="d-flex mt-3 f-light"></small>
                                                </Row>
                                                <Row>
                                                   <span
                                                      className={`badge ${
                                                         origen === "Low"
                                                            ? "badge-success"
                                                            : "badge-primary"
                                                      } f-right`}
                                                   >
                                                      {origen}
                                                   </span>
                                                </Row>
                                                <Row className="m-t-10">
                                                   <Button
                                                      color="dlk"
                                                      style={{fontSize: "13px"}}
                                                      onClick={() =>
                                                         handleEPropspectEdit({
                                                            id,
                                                         })
                                                      }
                                                   >
                                                      Más..
                                                   </Button>
                                                </Row>
                                             </div>
                                          </div>
                                       )}
                                    >
                                       {customboard}
                                    </Board>
                                 </main>
                              </div>
                           ) : (
                              <div className="loader-box">
                                 <div className="loader-15" />
                              </div>
                           )}
                        </CardBody>
                     </Card>
                  </Fragment>
               </Col>
               <div id="mydata"></div>
            </Row>
         </Container>
         {showModal && (
            <ProspectEditModal
               modal={true}
               title={"Editar prospecto"}
               toggle={toggleEdit}
               size="lg"
               reload={reload}
               prospecto={prospect}
               p_desarrollos={desarrollos}
               fuentes={canales}
               loadingFuentes={loadingCanales}
               etapas={
                  Array.isArray(embudos.columns)
                     ? embudos.columns.map((column) => ({
                          label: column.title,
                          value: column.id,
                       }))
                     : []
               }
               loadingEtapas={loadingEmbudo}
            />
         )}
      </>
   );
};

export default EtapasPage;
