import React, {useState} from "react";
import moment from "moment";
import Select from "react-select";
import DatePicker from "react-datepicker";
import {toast} from "react-toastify";
import {Form, FormGroup, Input, Label, Row, Col, Button} from "reactstrap";
import Request from "../../../../api/httpClient";

const request = new Request();

const EMINDER = [
   {value: 0, label: "No"},
   {value: 1, label: "Si"},
];

const TracingEdit = ({reload, tracing, handleModal}) => {
   const [isLoading, setIsLoading] = useState(false);
   const [subject, setSubject] = useState(tracing.subject);
   const [details, setDetails] = useState(tracing.detailnotes);
   const [selectedReminder, setSelectedReminder] = useState(tracing.rEvento);

   const [year, month, day] = tracing.fEvento.split("-").map(Number);
   const [eventDate, setEventDate] = useState(
      new Date(Date.UTC(year, month - 1, day + 1))
   );

   const [hour, minute, second] = tracing.hEvento.split(":").map(Number);
   const [eventTime, setEventTime] = useState(
      new Date(Date.UTC(year, month, day, hour + 5, minute, second))
   );

   const handleSubmit = async (event) => {
      event.preventDefault();
      setIsLoading(true);

      const fechaHoy = moment().format("YYYY-MM-DD");

      let data = {
         id_prospecto: tracing.fkclient,
         fechaVencimiento: moment(fechaHoy)
            .add(45, "days")
            .format("YYYY-MM-DD"),
         asunto: subject,
         detalles: details,
         rEvento:
            selectedReminder.value !== undefined ? selectedReminder.value : 0,
         fEvento: moment(eventDate).format("YYYY-MM-DD"),
         hEvento: moment(eventTime).format("HH:mm:ss"),
         id_seguimiento: tracing.pkbinnacle,
      };

      const response = await request.post(
         "/prospectos/prospecto/seguimiento/update",
         data
      );

      if (response && !response.error) {
         handleModal("edit");
         setIsLoading(false);
         reload("Seguimiento editado con éxito ");
      } else {
         setIsLoading(false);
         toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
         });
      }
   };

   return (
      <Form onSubmit={handleSubmit}>
         <FormGroup>
            <Row>
               <Col xl="4">
                  <Label className="col-form-label text-start">Recordar:</Label>
                  <Select
                     options={EMINDER}
                     value={EMINDER.find(
                        (item) => item.value === selectedReminder
                     )}
                     placeholder={"Seleccione..."}
                     onChange={(e) => setSelectedReminder(e)}
                     styles={{
                        menu: (provided) => ({
                           ...provided,
                           zIndex: 3,
                        }),
                     }}
                  />
               </Col>
               <Col xl="4">
                  <Label className="col-form-label text-start">
                     Fecha del evento:
                  </Label>
                  <DatePicker
                     className="form-control datetimepicker-input digits"
                     selected={eventDate}
                     onChange={(fecha) => setEventDate(fecha)}
                     dateFormat="dd/MM/yyyy"
                     required
                  />
               </Col>
               <Col xl="4">
                  <Label className="col-form-label text-start">
                     Hora del evento:
                  </Label>
                  <DatePicker
                     className="form-control datetimepicker-input digits"
                     selected={eventTime}
                     onChange={(hora) => setEventTime(hora)}
                     showTimeSelect
                     showTimeSelectOnly
                     timeIntervals={15}
                     timeCaption="Time"
                     dateFormat="h:mm aa"
                     required
                  />
               </Col>
               <Col xl="12">
                  <Label className="col-form-label text-start">Asunto:</Label>
                  <Input
                     id="asunto"
                     type="text"
                     value={subject}
                     onChange={(e) => {
                        setSubject(e.target.value);
                     }}
                     required
                  />
               </Col>
               <Col xl="12">
                  <Label className="col-form-label text-start">Detalles:</Label>
                  <Input
                     id="detalles"
                     type="textarea"
                     className="form-control"
                     rows="2"
                     value={details}
                     onChange={(e) => {
                        setDetails(e.target.value);
                     }}
                     required
                  />
               </Col>
               <div className="w-100 d-flex justify-content-end btn-showcase m-t-10">
                  <Button
                     className="m-r-10"
                     color="secondary"
                     onClick={() => handleModal("edit")}
                  >
                     Cancelar
                  </Button>
                  <Button type="submit" color="primary" disabled={isLoading}>
                     {isLoading ? "Guardando..." : "Guardar"}
                  </Button>
               </div>
            </Row>
         </FormGroup>
      </Form>
   );
};

export default TracingEdit;
