import Select from "react-select";
import {
   Container,
   Row,
   Col,
   Card,
   Form,
   FormGroup,
   Input,
   Button,
} from "reactstrap";
import {Breadcrumbs} from "../../AbstractElements";
import useUsers from "../../Hooks/users/useUsers";
import DataTableComponent from "../Tables/DataTable/DataTableComponent";
import UsuarioForm from "./UsuarioForm";
import UsuarioFormEdit from "./UsuarioFormEdit";
import UsuarioChangePassword from "./UsuarioChangePassword";
import ProspectList from "../Prospectos/components/ProspectList";
import ModalContainer from "../shared/ModalContainer";

const Users = () => {
   const {
      search,
      user,
      isLoading,
      showModal,
      handleModal,
      handleChange,
      filteredUsers,
      columns,
      USERS_TYPE,
      fetchUsers,
      type,
      setType,
   } = useUsers();

   return (
      <>
         <Breadcrumbs
            parent="Registros"
            title="Usuarios"
            mainTitle="Usuarios"
         />
         <Container fluid={true}>
            <Card style={{minHeight: `calc(90vh - 140px)`}}>
               <div className="m-20">
                  <Row>
                     <Col sm="5">
                        <div className="product-search feature-products">
                           <Form>
                              <FormGroup className="m-0 form-group search-product">
                                 <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="Buscar..."
                                    value={search}
                                    onChange={handleChange}
                                 />
                                 <i className="fa fa-search" />
                              </FormGroup>
                           </Form>
                        </div>
                     </Col>
                     <Col sm="3">
                        <Select
                           options={USERS_TYPE}
                           defaultValue={USERS_TYPE[0]}
                           placeholder={"Seleccionar estatus"}
                           onChange={(e) => setType(e?.type)}
                           isClearable
                           styles={{
                              menu: (provided) => ({
                                 ...provided,
                                 zIndex: 3,
                              }),
                           }}
                        />
                     </Col>
                     <Col sm="4">
                        <div className="text-end btn-showcase">
                           <Button
                              color="primary"
                              onClick={() => handleModal("create")}
                           >
                              <i className="fa fa-plus-square" /> Nuevo
                           </Button>
                        </div>
                     </Col>
                     <Col sm="12" className="m-t-10 m-b-8">
                        <DataTableComponent
                           tableColumns={columns}
                           tableRows={filteredUsers}
                           loadingData={isLoading}
                           pagination={true}
                           scrollHeight={"calc(84vh - 230px)"}
                           minHeight={`calc(82vh - 230px)`}
                        />
                     </Col>
                  </Row>
               </div>
            </Card>
         </Container>
         {showModal.create && (
            <UsuarioForm
               modal={true}
               title={"Nuevo usuario"}
               toggle={() => handleModal("create")}
               size="md"
               reload={fetchUsers}
               rol={type}
            />
         )}
         {showModal.edit && (
            <UsuarioFormEdit
               modal={true}
               title={"Editar usuario"}
               toggle={() => handleModal("edit")}
               size="md"
               reload={fetchUsers}
               usuario={user}
            />
         )}
         {showModal.change && (
            <UsuarioChangePassword
               modal={true}
               title={"Cambiar contraseña"}
               toggle={() => handleModal("change")}
               size="xs"
               reload={fetchUsers}
               id_usuario={user.pkuser}
               email={user.emailclient}
            />
         )}
         {showModal.prospectList && (
            <ModalContainer
               modal={true}
               open={() => handleModal("prospectList")}
               size="xl"
               title="Lista de prospectos"
               scroll={true}
               close={() => handleModal("prospectList")}
            >
               <ProspectList id={user.pkuser} />
            </ModalContainer>
         )}
      </>
   );
};

export default Users;
