import React, {useState, useEffect} from "react";
import {Col} from "react-bootstrap";
import {Form, FormGroup, Input, Label, Row} from "reactstrap";
import CommonModal from "../UiKits/Modals/common/modal";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {Btn} from "../../AbstractElements";
import Request from "../../api/httpClient";
import Select from "react-select";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {toast} from "react-toastify";

const request = new Request();

const UsuarioFormEdit = ({modal, title, toggle, size, reload, usuario}) => {
   const userId = localStorage.getItem("user_id") || 0;
   const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
   const [nombre, setNombre] = useState(usuario.username);
   const [email, setEmail] = useState(usuario.email);
   const [telefono, setTelefono] = useState(usuario.phone);
   const [isLoading, setIsLoading] = useState(false);
   const [internalAdvisors, setInternalAdvisors] = useState([]);
   const userRole = localStorage.getItem("Role");

   const tipoUsuarios = [
      {value: "A", label: "Administrador"},
      {value: "V", label: "Asesor Interno"},
      {value: "S", label: "Asesor Externo"},
      {value: "M", label: "Agencia"},
   ];
   const [tipoUsuario, setTipoUsuario] = useState(
      tipoUsuarios.find((tipo) => tipo.value == usuario.roll)
   );
   const comisiones = [
      {value: 2, label: 2},
      {value: 3, label: 3},
      {value: 4, label: 4},
      {value: 5, label: 5},
   ];
   const [comision, setComision] = useState(
      comisiones.find((com) => com.value === usuario.comision)
   );
   const tipoAsesores = [
      {value: "Interno", label: "Interno"},
      {value: "Externo", label: "Externo"},
   ];
   const tipoAsesor = tipoAsesores.find(
      (tipo) => tipo.value == usuario.sellerType
   );

   const fetchInternalAdvisors = async () => {
      setInternalAdvisors([]);
      setIsLoading(true);

      const response = await request.get("/usuarios/internal-advisors");

      if (response && !response.error) {
         if (response && !response.empty) {
            setInternalAdvisors(response);
         } else {
            setInternalAdvisors([]);
            setIsLoading(false);
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }

      setIsLoading(false);
   };

   const handleSubmit = async (event) => {
      event.preventDefault();
      setIsSubmitDisabled(true);

      // const advisor = internalAdvisors.find(
      //    (a) => a.value === selectedInternalAdvisor
      // );

      const editionDate = new Date();

      let data = {
         // parentuser: advisor?.id || localStorage.getItem("user_id"),
         id_usuario: usuario.pkuser,
         nombre: nombre,
         email: email,
         telefono: telefono,
         celular: telefono,
         tipoUsuario: tipoUsuario.value,
         comision: comision.value,
         tipoAsesor: tipoAsesor.value,
         currentPhone: usuario.phone,
         currentEmail: usuario.email,
         edit_date: editionDate,
         editor_id: userId,
      };

      const response = await request.post("/usuarios/usuario/update", data);

      if (response && !response.error) {
         toggle();
         setIsSubmitDisabled(false);
         reload("Usuario editado con éxito ");
      } else {
         setIsSubmitDisabled(false);
         toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
         });
      }
   };

   useEffect(() => {
      fetchInternalAdvisors();
   }, []);

   return (
      <CommonModal isOpen={modal} title={title} toggler={toggle} size={size}>
         <Modal isOpen={modal} toggle={toggle} size={size} centered>
            <Form onSubmit={handleSubmit}>
               <ModalHeader toggle={toggle}>{title}</ModalHeader>
               <ModalBody style={{paddingTop: "2px", paddingBottom: "2px"}}>
                  <Row>
                     <Col sm="12" md="12">
                        <div
                           className="project-box"
                           style={{paddingBottom: "5px"}}
                        >
                           <FormGroup className="row">
                              <Col xl="6" sm="12">
                                 <Label className="col-form-label text-start">
                                    Nombre:
                                 </Label>
                                 <Input
                                    id="nombre"
                                    type="text"
                                    value={nombre}
                                    onChange={(e) => {
                                       setNombre(e.target.value);
                                    }}
                                    required
                                 />
                              </Col>
                              <Col xl="6" sm="12">
                                 <Label className="col-form-label text-start">
                                    Email:
                                 </Label>
                                 <Input
                                    id="email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => {
                                       setEmail(e.target.value);
                                    }}
                                    required
                                 />
                              </Col>
                           </FormGroup>

                           <FormGroup className="row">
                              <Col xl="6" sm="12">
                                 <Label className="col-form-label text-start">
                                    Teléfono:
                                 </Label>
                                 <Input
                                    id="telefono"
                                    type="tel"
                                    value={telefono}
                                    minLength={10}
                                    maxLength={10}
                                    onChange={(e) => {
                                       const telefono = e.target.value.replace(
                                          /[^0-9]/g,
                                          ""
                                       );
                                       setTelefono(telefono);
                                    }}
                                 />
                              </Col>
                              <Col xl="6" sm="12">
                                 <Label className="col-form-label text-start">
                                    Comisión:
                                 </Label>
                                 <Select
                                    value={comision}
                                    options={comisiones}
                                    placeholder={"Seleccione..."}
                                    onChange={(comision) =>
                                       setComision(comision)
                                    }
                                    required
                                 />
                              </Col>
                           </FormGroup>

                           <FormGroup className="row">
                              <Col xl="12" sm="12">
                                 <Label className="col-form-label text-start">
                                    Tipo de usuario:
                                 </Label>
                                 <Select
                                    value={tipoUsuario}
                                    options={tipoUsuarios}
                                    placeholder={"Seleccione..."}
                                    onChange={(tipoUsuario) =>
                                       setTipoUsuario(tipoUsuario)
                                    }
                                    required
                                 />
                              </Col>
                              {tipoUsuario.value === "S" &&
                                 userRole === "A" && (
                                    <Col sm="12">
                                       <Label className="col-form-label text-start">
                                          Asesor interno:
                                       </Label>
                                       <Select
                                          isDisabled
                                          options={internalAdvisors}
                                          placeholder={
                                             "Seleccionar asesor interno"
                                          }
                                          value={internalAdvisors.find(
                                             (a) => a.id === usuario.parentuser
                                          )}
                                          isLoading={isLoading}
                                          styles={{
                                             menu: (provided) => ({
                                                ...provided,
                                                zIndex: 3,
                                             }),
                                          }}
                                       />
                                    </Col>
                                 )}
                           </FormGroup>
                        </div>
                     </Col>
                  </Row>
               </ModalBody>
               <ModalFooter>
                  <Btn attrBtn={{color: "secondary", onClick: toggle}}>
                     Cancelar
                  </Btn>
                  <Btn
                     attrBtn={{
                        type: "submit",
                        color: "primary",
                        disabled: isSubmitDisabled,
                     }}
                  >
                     {isSubmitDisabled ? "Guardando..." : "Guardar"}
                  </Btn>
               </ModalFooter>
            </Form>
         </Modal>
      </CommonModal>
   );
};

export default UsuarioFormEdit;
