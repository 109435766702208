import {Form, FormGroup, Input, Label, Row, Col} from "reactstrap";
import moment from "moment";

const ProspectZapierForm = ({prospect}) => {
   return (
      <Form>
         <Row>
            <div className="project-form-modal">
               <FormGroup>
                  <Row>
                     <Col xl="3" sm="12">
                        <Label className="col-form-label text-start">
                           Fecha:
                        </Label>
                        <Input
                           id="nombre"
                           type="text"
                           value={
                              prospect.zfecha
                                 ? moment(prospect.zfecha).format("DD/MM/YYYY")
                                 : ""
                           }
                           disabled
                        />
                     </Col>
                     <Col xl="12" sm="12">
                        <Label className="col-form-label text-start">
                           Origen:
                        </Label>
                        <Input
                           id="email"
                           type="text"
                           value={prospect.zorigen}
                           disabled
                        />
                     </Col>
                     <Col xl="12" sm="12">
                        <Label className="col-form-label text-start">
                           Plataforma:
                        </Label>
                        <Input
                           id="telefono"
                           type="text"
                           value={prospect.zplataforma}
                           disabled
                        />
                     </Col>
                     <Col xl="12" sm="12">
                        <Label className="col-form-label text-start">
                           Campaña:
                        </Label>
                        <Input
                           id="ciudad"
                           type="text"
                           value={prospect.zcampania}
                           disabled
                        />
                     </Col>
                     <Col xl="12" sm="12">
                        <Label className="col-form-label text-start">
                           Adset:
                        </Label>
                        <Input
                           id="ciudad"
                           type="text"
                           value={prospect.zadaset}
                           disabled
                        />
                     </Col>
                     <Col xl="12" sm="12">
                        <Label className="col-form-label text-start">
                           Adname:
                        </Label>
                        <Input
                           id="ciudad"
                           type="text"
                           value={prospect.zadname}
                           disabled
                        />
                     </Col>
                     <Col xl="12" sm="12">
                        <Label className="col-form-label text-start">
                           Presupuesto:
                        </Label>
                        <Input
                           id="ciudad"
                           type="text"
                           value={prospect.zpresumuesto}
                           disabled
                        />
                     </Col>
                  </Row>
               </FormGroup>
            </div>
         </Row>
      </Form>
   );
};

export default ProspectZapierForm;
