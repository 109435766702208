import React, {useContext, useEffect, useState} from "react";
import {Btn, H4, P} from "../../../AbstractElements";
import {Form, FormGroup, Input, Label, Media} from "reactstrap";
import {Link, useNavigate} from "react-router-dom";
import CustomizerContext from "../../../_helper/Customizer";
import Request from "../../../api/httpClient";
import {toast} from "react-toastify";
import Logo from "../../../assets/images/login/logo-login.png";
import Develoments from "../../../assets/images/login/develoments.png";
import {MD5} from "crypto-js";
const request = new Request();

const LoginForm = ({logoClassMain}) => {
   const [userEmail, setUserEmail] = useState(
      localStorage.getItem("userEmail") || ""
   );
   const [password, setPassword] = useState(
      localStorage.getItem("password") || ""
   );
   const [user_id, setUserId] = useState(localStorage.getItem("user_id") || 0);
   const [name, setName] = useState(localStorage.getItem("Name"));
   const [role, setRole] = useState(localStorage.getItem("Role"));
   const history = useNavigate();
   const {layoutURL} = useContext(CustomizerContext);
   const [loading, setLoading] = useState(false);
   const [rememberMe, setRememberMe] = useState(false);

   useEffect(() => {
      localStorage.setItem("user_id", user_id);
      localStorage.setItem("Name", name);
      localStorage.setItem("Role", role);
   }, [user_id, name, role]);

   const loginAuth = async (e) => {
      e.preventDefault();
      if (userEmail !== "" && password !== "") {
         setLoading(true);
         let data = {email: userEmail};
         const response = await request.post("/usuarios/usuario/login", data);

         const sanitizedPassword = password.replace(/[^a-zA-Z0-9]/g, "");
         const reversedPassword = sanitizedPassword
            .split("")
            .reverse()
            .join("");
         const encryptedPassword = MD5(reversedPassword).toString();

         if (response) {
            if (response.password === encryptedPassword) {
               if (response.state === 1) {
                  setUserId(response.pkuser);
                  setName(response.username);
                  setRole(response.roll);
                  localStorage.setItem("login", JSON.stringify(true));
                  localStorage.setItem("horaLogin", new Date().getTime());
                  localStorage.setItem("userEmail", userEmail);
                  if (rememberMe) {
                     localStorage.setItem("password", password);
                  } else {
                     localStorage.removeItem("password");
                  }
                  history(`/dashboard/default/${layoutURL}`);
               } else {
                  toast.error("El usuario se encuentra inactivo", {
                     position: toast.POSITION.BOTTOM_RIGHT,
                     autoClose: 2000,
                  });
               }
            } else {
               toast.error("Usuario o contraseña no válidos", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 2000,
               });
            }
         } else {
            toast.error(response.message, {
               position: toast.POSITION.BOTTOM_RIGHT,
               autoClose: 2000,
            });
         }
         setLoading(false);
      }
   };

   return (
      <div className="login-card">
         <div className="login-main">
            <div
               className={`logo ${logoClassMain ? logoClassMain : ""}`}
               to={process.env.PUBLIC_URL}
            >
               <Media src={Logo} alt="" style={{width: "25%"}} />
            </div>
            <Form className="theme-form login-form">
               <H4 attrH4={{className: "text-center text-light m-b-10"}}>
                  Sistema de Registro Eleva Capital Group
               </H4>
               <FormGroup>
                  <Input
                     className="form-control"
                     type="text"
                     required
                     onChange={(e) => setUserEmail(e.target.value)}
                     value={userEmail}
                     placeholder="Email"
                  />
               </FormGroup>
               <FormGroup className="position-relative">
                  <div className="position-relative">
                     <Input
                        className="form-control"
                        type={"password"}
                        name="login[password]"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        placeholder="Contraseña"
                     />
                  </div>
               </FormGroup>
               <>
                  {loading ? (
                     <div className="loader-box mt-3" style={{height: "20px"}}>
                        <div className="loader-15" />
                     </div>
                  ) : (
                     <>
                        <Btn
                           attrBtn={{
                              className: "d-block w-100 mt-3",
                              color: "primary",
                              type: "submit",
                              onClick: (e) => loginAuth(e),
                           }}
                        >
                           Iniciar sesión
                        </Btn>
                     </>
                  )}
                  <div
                     className="m-t-10 text-center fst-italic"
                     style={{color: "#fff", fontSize: "12px"}}
                  >
                     Si tienes problemas para iniciar sesión envia un email a
                     sistemas@elevacap.com con nombre completo y correo
                     electrónico
                  </div>
                  <P
                     attrPara={{
                        className: "m-t-10 mb-0 text-center text-light",
                     }}
                  >
                     <Link
                        className="ms-2"
                        to={`/pages/authentication/register-simple/Dubai`}
                     >
                        ¿Eres nuevo? Regístrate
                     </Link>
                  </P>
                  <div className="position-relative">
                     <div className="checkbox">
                        <Input
                           id="remember-me"
                           type="checkbox"
                           checked={rememberMe}
                           onChange={(e) => setRememberMe(e.target.checked)}
                        />
                        <Label style={{color: "blue"}} for="remember-me">
                           Recordar Contraseña
                        </Label>
                     </div>
                     <Link
                        className="link"
                        to={`/pages/authentication/forget-pwd/Dubai`}
                     >
                        ¿Olvidaste tu contraseña?
                     </Link>
                  </div>
                  <div className="m-t-10 text-center">
                     <div style={{color: "#D3AB1D"}}>
                        Al registrarte en nuestro sistema, asegura tu cliente y
                        tu comisión.
                     </div>
                     <div style={{color: "#fff"}}>
                        En cuanto recibas tu registro, un asesor de Eleva
                        Capital Group se pondrá en contacto contigo.
                     </div>
                  </div>
                  <div className="m-t-10 text-center">
                     <Media src={Develoments} alt="" style={{width: "90%"}} />
                  </div>
               </>
            </Form>
         </div>
      </div>
   );
};

export default LoginForm;
