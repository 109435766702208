import React from "react";
import moment from "moment";
import ProspectoMenu from "../../Components/Prospectos/ProspectoMenu";
import EditButton from "../../Components/shared/Buttons/EditButton";

export const prospectsColumns = (
   handleEditarProspecto,
   handleEliminarProspecto,
   handleCambiarStatusProspecto,
   handleClonarProspecto,
   otrosUsuariosInfo
) => [
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Registro</div>,
      excelname: "Registro",
      minWidth: "120px",
      width: 15,
      selector: (row) => moment(row.registerclient),
      cell: (row) => `${moment(row.registerclient).format("DD-MM-YYYY")}`,
      sortable: true,
      center: false,
   },
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Nombre</div>,
      excelname: "Nombre",
      minWidth: "150px",
      width: 25,
      selector: (row) => `${row.client}`,
      sortable: true,
      center: false,
   },
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Teléfono</div>,
      excelname: "Teléfono",
      minWidth: "120px",
      width: 15,
      selector: (row) => `${row.phoneclient}`,
      sortable: true,
      center: false,
   },
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Desarrollo</div>,
      excelname: "Desarrollo",
      minWidth: "150px",
      width: 20,
      selector: (row) => `${row?.nomDesarrollo || ""}`,
      sortable: true,
      center: false,
   },
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Etapa</div>,
      excelname: "Etapa",
      minWidth: "50px",
      width: 20,
      selector: (row) => `${row.funnelName}`,
      sortable: true,
      center: false,
      cell: (row) => {
         let badgeClass = "badge-light-light";
         let statusText = row.funnelName;

         return (
            <div style={{width: "100%", textAlign: "center"}}>
               <span className={`badge ${badgeClass}`}>{statusText}</span>
            </div>
         );
      },
   },
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Broker</div>,
      excelname: "Broker",
      minWidth: "50px",
      width: 15,
      selector: (row) => (row.broker === 1 ? "sí" : "no"),
      cell: (row) => (
         <div style={{width: "100%", textAlign: "center"}}>
            {row.broker ? (
               <span className="font-success">
                  <i className="icofont icofont-check"></i>
               </span>
            ) : (
               <span className="font-danger">
                  <i className="icofont icofont-close"></i>
               </span>
            )}
         </div>
      ),
      sortable: true,
      center: false,
   },
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Asesor</div>,
      excelname: "Usuario",
      minWidth: "150px",
      width: 20,
      selector: (row) => `${row.username}`,
      sortable: true,
      center: true,
      omit: otrosUsuariosInfo === 0,
   },
   {
      minWidth: "80px",
      button: true,
      ignoreRowClick: true,
      allowOverflow: true,
      center: true,
      selector: (row) => null,
      cell: (row) => <EditButton row={row} onEditRow={handleEditarProspecto} />,
   },
   {
      minWidth: "50px",
      button: true,
      ignoreRowClick: true,
      allowOverflow: true,
      center: false,
      selector: (row) => null,
      cell: (row) => (
         <ProspectoMenu
            size="small"
            row={row}
            onEditRow={handleEditarProspecto}
            onDeleteRow={handleEliminarProspecto}
            onUpdateStatusRow={handleCambiarStatusProspecto}
            onUpdateDesarrolloRow={handleClonarProspecto}
         />
      ),
   },
];
