import React from "react";
import {FaWhatsapp} from "react-icons/fa";

const WhatsappButton = () => {
   const handleOpenWhatsapp = () => {
      const message = encodeURIComponent(
         "¡Buen día! Quisiera contactarme con soporte de ELEVA Capital Group, tengo una duda sobre..."
      );
      window.open(
         `https://api.whatsapp.com/send?phone=9981686395&text=${message}`
      );
   };

   return (
      <>
         <li>
            <span style={{fontSize: "13px", color: "darkgray"}}>
               ¿Tienes alguna duda? Contáctanos
            </span>
         </li>
         <li>
            <div className="mode false" onClick={handleOpenWhatsapp}>
               <FaWhatsapp style={{fill: "darkgray"}} />
            </div>
         </li>
      </>
   );
};

export default WhatsappButton;
