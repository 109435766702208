import React, {Fragment, useContext, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {
   Container,
   Row,
   Col,
   Card,
   Form,
   FormGroup,
   Input,
   Nav,
   NavItem,
   NavLink,
} from "reactstrap";
import {Breadcrumbs, Btn} from "../../AbstractElements";
import Request from "../../api/httpClient";
import DataTableComponent from "../Tables/DataTable/DataTableComponent";
import {Box} from "react-feather";
import DesarrollosContext from "../../_helper/Desarrollos";
import DisponibilidadFormEdit from "./DisponibilidadEditForm";

const request = new Request();

const DisponibilidadPage = () => {
   const [searchTerm, setSearchTerm] = useState("");
   const [departamentos, setDepartamentos] = useState([]);
   const {desarrollos} = useContext(DesarrollosContext);
   const [departamento, setDepartamento] = useState(null);
   const [loadingDepartamentos, setLoadingDepartamentos] = useState(false);

   const [modalEdit, setModalEdit] = useState(false);
   const toggleEdit = () => setModalEdit(!modalEdit);
   const [desarrolloSelec, setDesarrolloSelec] = useState({});

   const handleInputChange = (event) => {
      setSearchTerm(event.target.value);
   };

   const filteredData = departamentos.filter(
      (item) =>
         item.depto.toLowerCase().includes(searchTerm.toLowerCase()) ||
         item.estado.toLowerCase().includes(searchTerm.toLowerCase())
   );

   const getDisponibilidad = async () => {
      setDepartamentos([]);
      setLoadingDepartamentos(true);
      const data = {
         id_desarrollo: desarrolloSelec.IDdesarrollo || 0,
      };

      const response = await request.post(
         "/disponibilidad/get/departamentos",
         data
      );
      if (response && !response.error) {
         if (response && !response.empty) {
            setDepartamentos(response);
         } else {
            setDepartamentos([]);
            setLoadingDepartamentos(false);
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }
      setLoadingDepartamentos(false);
   };

   useEffect(() => {
      if (desarrollos.length > 0) {
         setDesarrolloSelec(desarrollos[0]);
      }
   }, [desarrollos]);

   useEffect(() => {
      if (desarrollos.length > 0 && Object.keys(desarrolloSelec).length !== 0) {
         getDisponibilidad();
      }
   }, [desarrolloSelec]);

   const handleEditarDpto = (row) => {
      setDepartamento(row);
      toggleEdit();
   };

   const tableColumns = [
      {
         name: <div style={{width: "100%", textAlign: "center"}}>Dpto</div>,
         minWidth: "20px",
         selector: (row) => `${row.depto}`,
         cell: (row) => (
            <div style={{width: "100%", textAlign: "center"}}>{row.depto}</div>
         ),
         sortable: true,
         center: false,
      },
      {
         name: <div style={{width: "100%", textAlign: "center"}}>Piso</div>,
         minWidth: "20px",
         selector: (row) => `${row.piso}`,
         cell: (row) => (
            <div style={{width: "100%", textAlign: "center"}}>{row.piso}</div>
         ),
         sortable: true,
         center: false,
      },
      {
         name: <div style={{width: "100%", textAlign: "center"}}>Tipo</div>,
         minWidth: "20px",
         selector: (row) => `${row.tipo}`,
         cell: (row) => (
            <div style={{width: "100%", textAlign: "center"}}>{row.tipo}</div>
         ),
         sortable: true,
         center: false,
      },
      {
         name: <div style={{width: "100%", textAlign: "center"}}>Interior</div>,
         minWidth: "20px",
         selector: (row) => `${row.minterior}`,
         cell: (row) => (
            <div style={{width: "100%", textAlign: "center"}}>
               {row.minterior}
            </div>
         ),
         sortable: true,
         center: false,
      },
      {
         name: <div style={{width: "100%", textAlign: "center"}}>Terraza</div>,
         minWidth: "20px",
         selector: (row) => `${row.mterraza}`,
         cell: (row) => (
            <div style={{width: "100%", textAlign: "center"}}>
               {row.mterraza}
            </div>
         ),
         sortable: true,
         center: false,
      },
      {
         name: (
            <div style={{width: "100%", textAlign: "center"}}>Interesados</div>
         ),
         minWidth: "50px",
         sortable: true,
         center: false,
      },
      {
         name: <div style={{width: "100%", textAlign: "center"}}>Precio</div>,
         minWidth: "50px",
         selector: (row) => `${row.precio}`,
         cell: (row) => (
            <div style={{width: "100%", textAlign: "center"}}>{row.precio}</div>
         ),
         sortable: true,
         center: false,
      },
      {
         name: <div style={{width: "100%", textAlign: "center"}}>Estado</div>,
         minWidth: "20px",
         selector: (row) => `${row.estado}`,
         cell: (row) => {
            let badgeClass = "";
            let statusText = "";

            switch (row.estado) {
               case "D":
                  statusText = "Disponible";
                  badgeClass = "badge-success";
                  break;
               case "V":
                  statusText = "Vendido";
                  badgeClass = "badge-danger";
                  break;
               case "A":
                  statusText = "Apartado";
                  badgeClass = "badge-info";
                  break;

               default:
                  badgeClass = "badge-light-light";
                  statusText = "Otro";
            }

            return (
               <div style={{width: "100%", textAlign: "center"}}>
                  <span className={`badge ${badgeClass}`}>{statusText}</span>
               </div>
            );
         },
         sortable: true,
         center: false,
      },
      {
         name: <div style={{width: "100%", textAlign: "center"}}>Preventa</div>,
         minWidth: "50px",
         selector: (row) => `${row.preventa}`,
         cell: (row) => (
            <div style={{width: "100%", textAlign: "center"}}>
               {row.preventa ? "Si" : "No"}
            </div>
         ),
         sortable: true,
         center: false,
      },
      {
         name: (
            <div style={{width: "100%", textAlign: "center"}}>
               Ficha técnica
            </div>
         ),
         minWidth: "120px",
         button: true,
         ignoreRowClick: true,
         allowOverflow: true,
         center: false,
         cell: (row) => (
            <Row>
               <Col
                  xl="6"
                  md="12"
                  style={{
                     display: "block",
                  }}
               >
                  <Btn
                     attrBtn={{
                        style: {
                           width: 100,
                           fontSize: 14,
                        },
                        color: "secondary",
                        className: "btn btn-xs",
                        type: "button",
                     }}
                  >
                     <i className="fa fa-cloud-download">
                        {" "}
                        <span style={{fontFamily: "Rubik, sans-serif"}}>
                           Descargar
                        </span>
                     </i>
                  </Btn>
               </Col>
            </Row>
         ),
      },
      {
         name: <div style={{width: "100%", textAlign: "center"}}>Acción</div>,
         minWidth: "120px",
         button: true,
         ignoreRowClick: true,
         allowOverflow: true,
         center: false,
         cell: (row) => (
            <Row>
               <Col
                  xl="6"
                  md="12"
                  style={{
                     display: "block",
                  }}
               >
                  <Btn
                     attrBtn={{
                        style: {
                           width: 80,
                           fontSize: 14,
                        },
                        color: "info",
                        className: "btn btn-xs",
                        type: "button",
                        onClick: () => {
                           handleEditarDpto(row);
                        },
                     }}
                  >
                     <i className="fa fa-edit">
                        {" "}
                        <span style={{fontFamily: "Rubik, sans-serif"}}>
                           Editar
                        </span>
                     </i>
                  </Btn>
               </Col>
            </Row>
         ),
      },
   ];

   const reload = (message) => {
      toast.success(message, {
         position: toast.POSITION.BOTTOM_RIGHT,
         autoClose: 2000,
      });
      getDisponibilidad();
   };

   return (
      <Fragment>
         <Breadcrumbs
            parent="Desarrollos"
            title="Disponibilidad"
            mainTitle="Disponibilidad"
         />
         <Container fluid={true}>
            <Row>
               <Col md="12" className="project-list">
                  <Card>
                     <Row>
                        <Col md="12">
                           <Nav tabs className="border-tab">
                              {desarrollos.map((des, index) => (
                                 <NavItem key={index}>
                                    <NavLink
                                       className={
                                          desarrolloSelec.nomDesarrollo ===
                                          des.nomDesarrollo
                                             ? "active"
                                             : ""
                                       }
                                       onClick={() => setDesarrolloSelec(des)}
                                    >
                                       <Box />
                                       {des.nomDesarrollo}
                                    </NavLink>
                                 </NavItem>
                              ))}
                           </Nav>
                        </Col>
                     </Row>
                  </Card>
               </Col>
               <Col>
                  <Card style={{minHeight: `calc(65vh - 135px)`}}>
                     <Col>
                        <Row className="m-20">
                           <Col sm="6">
                              <div className="product-search feature-products">
                                 <Form>
                                    <FormGroup className="m-0 form-group search-product">
                                       <Input
                                          className="form-control"
                                          type="text"
                                          placeholder="Buscar..."
                                          value={searchTerm}
                                          onChange={handleInputChange}
                                       />
                                       <i className="fa fa-search"></i>
                                    </FormGroup>
                                 </Form>
                              </div>
                           </Col>
                        </Row>
                     </Col>
                     <Col className="m-l-20 m-r-20 m-b-8">
                        <DataTableComponent
                           tableColumns={tableColumns}
                           tableRows={filteredData}
                           loadingData={loadingDepartamentos}
                           pagination={true}
                           scrollHeight={"calc(63vh - 170px)"}
                           minHeight={`calc(58vh - 170px)`}
                        />
                     </Col>
                  </Card>
               </Col>
            </Row>
         </Container>
         {modalEdit && (
            <DisponibilidadFormEdit
               modal={true}
               title={"Editar departamento"}
               toggle={toggleEdit}
               size="lg"
               reload={reload}
               departamento={departamento}
            />
         )}
      </Fragment>
   );
};

export default DisponibilidadPage;
