import React, {Fragment, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {Container, Row, Col, Card} from "reactstrap";
import {Breadcrumbs} from "../../AbstractElements";
import Request from "../../api/httpClient";
import DataTableComponent from "../Tables/DataTable/DataTableComponent";
import DeleteIcon from "@mui/icons-material/Delete";

import moment from "moment";

const request = new Request();

const RecordatoriosPage = () => {
   const [recordatorios, setRecordatorios] = useState([]);
   const [loadingRecordatorios, setLoadingRecordatorios] = useState(false);

   const getRecordatorios = async () => {
      setRecordatorios([]);
      setLoadingRecordatorios(true);
      const data = {
         id_usuario: localStorage.getItem("user_id") || 0,
      };

      const response = await request.post("/prospectos/recordatorios", data);
      if (response && !response.error) {
         if (response && !response.empty) {
            setRecordatorios(response);
         } else {
            setRecordatorios([]);
            setLoadingRecordatorios(false);
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }
      setLoadingRecordatorios(false);
   };

   useEffect(() => {
      getRecordatorios();
   }, []);

   const tableColumns = [
      {
         name: <div>Prospecto</div>,
         minWidth: "80px",
         maxWidth: "150px",
         selector: (row) => `${row.client}`,
         sortable: true,
         center: false,
      },
      {
         name: <div style={{width: "100%"}}>Datos de contacto</div>,
         minWidth: "200px",
         maxWidth: "300px",
         selector: (row) => (
            <Row className="details">
               <Col xs="4">
                  <span className="f-w-500">Teléfono: </span>
               </Col>
               <Col xs="8">{row.phoneclient}</Col>
               <Col xs="4">
                  <span className="f-w-500">Correo:</span>
               </Col>
               <Col xs="8">{row.emailclient}</Col>
            </Row>
         ),
         sortable: true,
         center: false,
      },
      {
         name: <div style={{width: "100%"}}>Detalles</div>,
         minWidth: "400px",
         maxWidth: "700px",
         selector: (row) => (
            <Row className="details">
               <Col xs="4">
                  <span className="f-w-500">Fecha evento: </span>
               </Col>
               <Col xs="8">{moment(row.fEvento).format("DD-MM-YYYY")}</Col>
               <Col xs="4">
                  <span className="f-w-500">Asunto:</span>
               </Col>
               <Col xs="8">{row.subject}</Col>
               <Col xs="4">
                  <span className="f-w-500">Detalles:</span>
               </Col>
               <Col xs="8">{row.detailnotes}</Col>
            </Row>
         ),
         sortable: true,
         center: false,
      },
      {
         name: (
            <div style={{width: "100%", textAlign: "center"}}>Seguimiento</div>
         ),
         minWidth: "50px",
         maxWidth: "120px",
         selector: (row) => `${row.historial}`,
         sortable: true,
         center: false,
      },
      {
         name: <div style={{width: "100%", textAlign: "center"}}>Vigente</div>,
         minWidth: "80px",
         maxWidth: "120px",
         cell: (row) => (
            <div style={{width: "100%", textAlign: "center"}}>
               {new Date(row.fEvento) < new Date() ? (
                  <span className="font-danger">
                     <i className="icofont icofont-close"></i>
                  </span>
               ) : (
                  <span className="font-success">
                     <i className="icofont icofont-check"></i>
                  </span>
               )}
            </div>
         ),
         sortable: true,
         center: false,
      },
      {
         name: "",
         minWidth: "50px",
         button: true,
         ignoreRowClick: true,
         allowOverflow: true,
         center: false,
         cell: (row) => (
            <Col
               xl="6"
               md="12"
               style={{
                  display:
                     new Date(row.fEvento) < new Date() ? "block" : "none",
               }}
               onClick={() => {
                  handleEliminarRecordatorio(row);
               }}
            >
               <DeleteIcon fontSize="small" color="error" />
            </Col>
         ),
      },
   ];

   const handleEliminarRecordatorio = async (row) => {
      let data = {
         id_recordatorio: row.pkbinnacle,
      };

      const response = await request.post(
         "/prospectos/recordatorios/recordatorio/delete",
         data
      );
      if (response && !response.error) {
         reload("Recordatorio eliminado con éxito ");
      } else {
         toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
         });
      }
   };

   const reload = (message) => {
      toast.success(message, {
         position: toast.POSITION.BOTTOM_RIGHT,
         autoClose: 2000,
      });
      getRecordatorios();
   };

   const conditionalRowStyles = [
      {
         when: (row) => new Date(row.fEvento) < new Date(),
         style: {
            color: "red",
         },
      },
   ];

   return (
      <Fragment>
         <Breadcrumbs
            parent="Gestión"
            title="Recordatorios"
            mainTitle="Recordatorios"
         />
         <Container fluid={true}>
            <Row>
               <Card style={{minHeight: `calc(92vh - 142px)`}}>
                  <div>
                     <DataTableComponent
                        tableColumns={tableColumns}
                        tableRows={recordatorios}
                        loadingData={loadingRecordatorios}
                        pagination={true}
                        conditionalRowStyles={conditionalRowStyles}
                        scrollHeight={"calc(90vh - 160px)"}
                        minHeight={`calc(86vh - 160px)`}
                     />
                  </div>
               </Card>
            </Row>
         </Container>
      </Fragment>
   );
};

export default RecordatoriosPage;
