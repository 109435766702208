import React, {useEffect, useState} from "react";
import moment from "moment";
import Select from "react-select";
import {toast} from "react-toastify";
import {Form, FormGroup, Input, Label, Row, Col, Button} from "reactstrap";
import Request from "../../../../api/httpClient";

const request = new Request();

const ZapiersEditForm = ({lead, toggle, fetchZapier}) => {
   const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [internalAdvisors, setInternalAdvisors] = useState([]);
   const [selectedInternalAdvisor, setSelectedInternalAdvisor] = useState("");

   const fetchInternalAdvisors = async () => {
      setInternalAdvisors([]);
      setIsLoading(true);

      const response = await request.get("/usuarios/internal-advisors");

      if (response && !response.error) {
         if (response && !response.empty) {
            setInternalAdvisors(response);
         } else {
            setInternalAdvisors([]);
            setIsLoading(false);
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }

      setIsLoading(false);
   };

   const handleSubmit = async () => {
      if (!selectedInternalAdvisor) {
         toast.error("Selecciona un asesor interno", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
         });
         return;
      }

      const date = moment().format("YYYY-MM-DD");
      const advisor = internalAdvisors.find(
         (a) => a.value === selectedInternalAdvisor
      );

      const data = {
         id: lead.id,
         advisorId: advisor?.id || 0,
         date: date,
         expirationDate: moment(date).add(45, "days").format("YYYY-MM-DD"),
         advisorName: advisor?.label || "",
         leadName: `${lead.Nombre} ${lead.Apellido}`,
         leadEmail: lead?.Email || "",
         leadPhone: lead?.WhatsApp || "",
         interest: "",
         developmentId: 5,
         stageId: 1,
      };

      const response = await request.post(
         "/prospectos/assign/internal-advisor",
         data
      );

      if (response && !response.error) {
         toggle();
         setIsSubmitDisabled(false);
         toast.success("Asesor interno asignado con éxito", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
         });
         fetchZapier();
      } else {
         setIsSubmitDisabled(false);
         toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
         });
      }
   };

   useEffect(() => {
      fetchInternalAdvisors();
   }, []);

   return (
      <Form>
         <div className="project-form-modal">
            <FormGroup>
               <Row>
                  <Col xl="4" sm="12">
                     <Label className="col-form-label text-start">
                        Nombre:
                     </Label>
                     <Input
                        defaultValue={lead.Nombre}
                        id="nombre"
                        type="text"
                        disabled
                     />
                  </Col>
                  <Col xl="4" sm="12">
                     <Label className="col-form-label text-start">
                        Apellido:
                     </Label>
                     <Input
                        defaultValue={lead.Apellido}
                        id="nombre"
                        type="text"
                        disabled
                     />
                  </Col>
                  <Col xl="4" sm="12">
                     <Label className="col-form-label text-start">
                        Teléfono:
                     </Label>
                     <Input
                        defaultValue={lead.WhatsApp}
                        id="nombre"
                        type="text"
                        disabled
                     />
                  </Col>
                  <Col xl="4" sm="12">
                     <Label className="col-form-label text-start">Email:</Label>
                     <Input
                        defaultValue={lead.Email}
                        id="nombre"
                        type="text"
                        disabled
                     />
                  </Col>
                  <Col xl="4" sm="12">
                     <Label className="col-form-label text-start">
                        Origen:
                     </Label>
                     <Input
                        defaultValue={lead.Origen}
                        id="nombre"
                        type="text"
                        disabled
                     />
                  </Col>
                  <Col xl="4" sm="12">
                     <Label className="col-form-label text-start">
                        Campaña:
                     </Label>
                     <Input
                        defaultValue={lead.Campanya}
                        id="nombre"
                        type="text"
                        disabled
                     />
                  </Col>
                  <Col xl="4" sm="12">
                     <Label className="col-form-label text-start">AdSet:</Label>
                     <Input
                        defaultValue={lead.AdSet}
                        id="nombre"
                        type="text"
                        disabled
                     />
                  </Col>
                  <Col xl="4" sm="12">
                     <Label className="col-form-label text-start">
                        AdName:
                     </Label>
                     <Input
                        defaultValue={lead.AdName}
                        id="nombre"
                        type="text"
                        disabled
                     />
                  </Col>
                  <Col xl="4" sm="12">
                     <Label className="col-form-label text-start">
                        Plataforma:
                     </Label>
                     <Input
                        defaultValue={lead.Platform}
                        id="nombre"
                        type="text"
                        disabled
                     />
                  </Col>
                  <Col xl="4" sm="12">
                     <Label className="col-form-label text-start">
                        Ciudad:
                     </Label>
                     <Input
                        defaultValue={lead.Ciudad}
                        id="nombre"
                        type="text"
                        disabled
                     />
                  </Col>
                  <Col xl="4" sm="12">
                     <Label className="col-form-label text-start">
                        Interes:
                     </Label>
                     <Input
                        defaultValue={lead.Interes}
                        id="nombre"
                        type="text"
                        disabled
                     />
                  </Col>
                  <Col xl="4" sm="12">
                     <Label className="col-form-label text-start">
                        Presupuesto:
                     </Label>
                     <Input
                        defaultValue={lead.Presupuesto}
                        id="nombre"
                        type="text"
                        disabled
                     />
                  </Col>
                  <Col xl="4" sm="12">
                     <Label className="col-form-label text-start">
                        Asesor interno:
                     </Label>
                     <Select
                        options={internalAdvisors}
                        value={internalAdvisors.find(
                           (a) => a.id === lead.pkuser
                        )}
                        placeholder={"Seleccionar asesor interno"}
                        onChange={(e) => setSelectedInternalAdvisor(e?.value)}
                        isLoading={isLoading}
                        styles={{
                           menu: (provided) => ({
                              ...provided,
                              zIndex: 3,
                           }),
                        }}
                     />
                  </Col>
               </Row>
            </FormGroup>
            <div className="w-100 d-flex justify-content-end btn-showcase">
               <Button color="secondary" onClick={toggle} className="m-r-10">
                  Cancelar
               </Button>
               <Button
                  color="primary"
                  disabled={isSubmitDisabled}
                  onClick={handleSubmit}
               >
                  {isSubmitDisabled ? "Guardando..." : "Guardar"}
               </Button>
            </div>
         </div>
      </Form>
   );
};

export default ZapiersEditForm;
