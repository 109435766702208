import React, {Fragment, useContext, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {
   Container,
   Row,
   Col,
   Card,
   CardBody,
   FormGroup,
   Input,
} from "reactstrap";
import Swal from "sweetalert2";
import {Breadcrumbs, Btn} from "../../AbstractElements";
import Request from "../../api/httpClient";
import HeaderCard from "../Common/Component/HeaderCard";
import MotivosContext from "../../_helper/Motivos";

const request = new Request();

const MotivosPage = () => {
   const {motivos, setMotivos, getMotivos} = useContext(MotivosContext);
   const [nuevoMotivo, setNuevoMotivo] = useState("");
   const [modifiedIndex, setModifiedIndex] = useState(null);

   useEffect(() => {
      if (motivos.length > 0) {
         setNuevoMotivo("");
      }
   }, [motivos]);

   const handleEliminarMotivo = (row) => {
      Swal.fire({
         title: "Está seguro de eliminar este motivo?",
         text: "Se eliminará el motivo: " + row.reasonName,
         icon: "warning",
         showCancelButton: true,
         confirmButtonColor: "#3085d6",
         cancelButtonColor: "#d33",
         confirmButtonText: "Si, Eliminar",
         cancelButtonText: "Cancelar",
         reverseButtons: true,
      }).then(async (result) => {
         if (result.isConfirmed) {
            let data = {
               id_motivo: row.reasonId,
            };

            const response = await request.post("/motivos/motivo/delete", data);
            if (response && !response.error) {
               Swal.fire(
                  "Eliminado!",
                  "Este motivo ha sido eliminado",
                  "success"
               );
               getMotivos();
            } else {
               toast.error(response.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 2000,
               });
            }
         }
      });
   };

   const handleNuevoMotivo = () => {
      Swal.fire({
         title: "Está seguro de crear un nuevo motivo?",
         text: "Se creará el motivo: " + nuevoMotivo,
         icon: "info",
         showCancelButton: true,
         confirmButtonColor: "#3085d6",
         cancelButtonColor: "#d33",
         confirmButtonText: "Si, Crear",
         cancelButtonText: "Cancelar",
         reverseButtons: true,
      }).then(async (result) => {
         if (result.isConfirmed) {
            let data = {
               nombre_motivo: nuevoMotivo,
            };

            const response = await request.post("/motivos/motivo/create", data);
            if (response && !response.error) {
               Swal.fire("Creado!", "Se ha creado un nuevo motivo", "success");
               getMotivos();
            } else {
               toast.error(response.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 2000,
               });
            }
         }
      });
   };

   const handleEditarMotivo = () => {
      Swal.fire({
         title: "Está seguro de editar este motivo?",
         text: "Se editará el motivo: " + motivos[modifiedIndex].reasonName,
         icon: "info",
         showCancelButton: true,
         confirmButtonColor: "#3085d6",
         cancelButtonColor: "#d33",
         confirmButtonText: "Si, Editar",
         cancelButtonText: "Cancelar",
         reverseButtons: true,
      }).then(async (result) => {
         if (result.isConfirmed) {
            let data = {
               id_motivo: motivos[modifiedIndex].reasonId,
               nombre_motivo: motivos[modifiedIndex].reasonName,
            };

            const response = await request.post("/motivos/motivo/update", data);
            if (response && !response.error) {
               Swal.fire("Editado!", "Motivo editado con éxito", "success");
               getMotivos();
            } else {
               toast.error(response.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 2000,
               });
            }
         }
      });
   };

   const handleInputChange = (e, index) => {
      const {value} = e.target;
      setMotivos((prevMotivos) => {
         const newMotivos = [...prevMotivos];
         newMotivos[index].reasonName = value;
         return newMotivos;
      });
      setModifiedIndex(index);
   };

   return (
      <Fragment>
         <Breadcrumbs parent="Gestión" title="Motivos" mainTitle="Motivos" />
         <Container fluid={true}>
            <Row>
               <Col xl="6" className="box-col-6">
                  <Card>
                     <HeaderCard title="Motivos" />
                     <CardBody>
                        {motivos.map((motivo, index) => (
                           <FormGroup className="row">
                              <Col xs="9" md="9">
                                 <Input
                                    value={motivo.reasonName}
                                    id={index}
                                    type="text"
                                    onChange={(e) =>
                                       handleInputChange(e, index)
                                    }
                                 />
                              </Col>
                              <Col xs="3" md="3">
                                 <Btn
                                    attrBtn={{
                                       color: "secondary",
                                       style: {
                                          padding: "5px 10px",
                                          marginTop: "3px",
                                       },
                                       onClick: () => {
                                          handleEliminarMotivo(motivo);
                                       },
                                    }}
                                 >
                                    <i className="fa fa-trash"></i>
                                 </Btn>
                                 {modifiedIndex === index && (
                                    <Btn
                                       attrBtn={{
                                          color: "success",
                                          style: {
                                             padding: "5px 10px",
                                             marginTop: "3px",
                                             marginLeft: "10px",
                                          },
                                          onClick: () => {
                                             handleEditarMotivo();
                                             setModifiedIndex(null);
                                          },
                                       }}
                                    >
                                       <i className="fa fa-check"></i>
                                    </Btn>
                                 )}
                              </Col>
                           </FormGroup>
                        ))}
                        <FormGroup className="row">
                           <Col xs="9" md="9">
                              <Input
                                 id="nuevo"
                                 type="text"
                                 value={nuevoMotivo}
                                 onChange={(e) => {
                                    setNuevoMotivo(e.target.value);
                                 }}
                                 placeholder="Nuevo motivo"
                              />
                           </Col>
                           <Col xs="3" md="3">
                              <Btn
                                 attrBtn={{
                                    color: "info",
                                    style: {
                                       padding: "5px 10px",
                                       marginTop: "3px",
                                    },
                                    onClick: () => {
                                       handleNuevoMotivo();
                                    },
                                 }}
                              >
                                 <i className="fa fa-plus"></i>
                              </Btn>
                           </Col>
                        </FormGroup>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </Container>
      </Fragment>
   );
};

export default MotivosPage;
