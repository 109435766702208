import React, {useEffect, useState} from "react";
import {Form, FormGroup, Input, Label, Row, Col} from "reactstrap";
import {Btn} from "../../../../AbstractElements";
import Request from "../../../../api/httpClient";
import Select from "react-select";
import {toast} from "react-toastify";
import moment from "moment";

const ProspectEditForm = ({
   toggle,
   reload,
   p_desarrollos,
   prospecto,
   fuentes,
   etapas,
}) => {
   const userId = localStorage.getItem("user_id") || 0;
   const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
   const [nombre, setNombre] = useState(prospecto.client);
   const [email, setEmail] = useState(prospecto.emailclient);
   const [telefono, setTelefono] = useState(prospecto.phoneclient);
   const [ciudad, setCiudad] = useState(prospecto.city);
   const [comentarios, setComentarios] = useState(prospecto.notes);
   const desarrollos = p_desarrollos.map((item) => {
      return {
         label: item.nomDesarrollo,
         value: item.IDdesarrollo,
      };
   });
   const [desarrollo, setDesarrollo] = useState(
      desarrollos.find((item) => item.value === prospecto.desarrolloID)
   );
   const [calificaciones] = useState([
      {value: 1, label: 1},
      {value: 2, label: 2},
      {value: 3, label: 3},
      {value: 4, label: 4},
   ]);
   const [calificacion, setCalificacion] = useState(
      calificaciones.find((item) => item.value === prospecto.calificacion)
   );
   const [fuente, setFuente] = useState(
      fuentes.find((item) => item.value === prospecto.origin)
   );
   const [departamentos, setDepartamentos] = useState([]);
   const [departamentosSelec, setDepartamentosSelec] = useState(null);
   const [loadingDepartamentos, setLoadingDepartamentos] = useState(false);
   const [etapa, setEtapa] = useState(
      etapas.find((item) => item.value === prospecto.funnelId)
   );
   const [posponerOpciones] = useState([
      {value: "F&F", label: "F&F"},
      {value: "1 Mes", label: "1 Mes"},
      {value: "2 Meses", label: "2 Meses"},
      {value: "3 Meses", label: "3 Meses"},
      {value: "Próximo año", label: "Próximo año"},
   ]);
   const [posponer, setPosponer] = useState(
      posponerOpciones.find((item) => item.value === prospecto.posponer) || null
   );

   const [emailRecuperacion1_fecha] = useState(
      prospecto.emailRecuperacion1_fecha
   );
   const [emailRecuperacion2_fecha] = useState(
      prospecto.emailRecuperacion2_fecha
   );
   const [emailRecuperacion3_fecha] = useState(
      prospecto.emailRecuperacion3_fecha
   );

   const request = new Request();

   useEffect(() => {
      if (desarrollo) {
         getDepartamentos();
      }
   }, [desarrollo]);

   const handleSubmit = async (event) => {
      event.preventDefault();
      setIsSubmitDisabled(true);

      const editionDate = new Date();

      let data = {
         id_prospecto: prospecto.pkclient,
         cliente: nombre,
         email: email,
         telefono: telefono,
         ciudad: ciudad,
         budget: "",
         comentarios: comentarios,
         interes: departamentosSelec ? departamentosSelec.label : "",
         origen: fuente.value,
         origen_otro: "",
         broker: false,
         calificacion: calificacion.value,
         id_desarrollo: desarrollo?.value || prospecto.desarrolloID,
         id_embudo: etapa.value,
         posponer: posponer && posponer.value,
         advisorId: prospecto.pkuser,
         currentPhone: prospecto.phoneclient,
         currentEmail: prospecto.emailclient,
         edit_date: editionDate,
         editor_id: userId,
      };

      const response = await request.post("/prospectos/prospecto/update", data);

      if (response && !response.error) {
         toggle();
         setIsSubmitDisabled(false);
         setPosponer(null);
         reload(
            true,
            "Prospecto editado con éxito ",
            desarrollo?.value || prospecto.desarrolloID
         );
      } else {
         setIsSubmitDisabled(false);
         toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
         });
      }
   };

   //obtener todos los departamentos por desarrollo
   const getDepartamentos = async () => {
      setDepartamentos([]);
      setLoadingDepartamentos(true);
      const data = {
         id_desarrollo: desarrollo.value,
      };
      const response = await request.post(
         "/prospectos/get/departamentos",
         data
      );
      if (response && !response.error) {
         if (response && !response.empty) {
            const dptos = response.map((dpto) => {
               return {
                  value: dpto.pkdepto,
                  label: dpto.depto,
               };
            });
            setDepartamentos(dptos);
            setDepartamentosSelec(
               dptos.find((item) => item.label === prospecto.interesting)
            );
         } else {
            setDepartamentos([]);
            setLoadingDepartamentos(false);
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }
      setLoadingDepartamentos(false);
   };

   return (
      <Form onSubmit={handleSubmit}>
         <Row>
            <div className="project-form-modal">
               <FormGroup>
                  <Row>
                     <Col xl="4" sm="12">
                        <Label className="col-form-label text-start">
                           Nombre:
                        </Label>
                        <Input
                           value={nombre}
                           id="nombre"
                           type="text"
                           onChange={(e) => {
                              setNombre(e.target.value);
                           }}
                           required
                        />
                     </Col>
                     <Col xl="4" sm="12">
                        <Label className="col-form-label text-start">
                           Email:
                        </Label>
                        <Input
                           value={email}
                           id="email"
                           type="email"
                           onChange={(e) => {
                              setEmail(e.target.value);
                           }}
                           required
                        />
                     </Col>
                     <Col xl="4" sm="12">
                        <Label className="col-form-label text-start">
                           Teléfono:
                        </Label>
                        <Input
                           value={telefono}
                           id="telefono"
                           type="tel"
                           minLength={10}
                           maxLength={15}
                           onChange={(e) => {
                              const telefono = e.target.value.replace(
                                 /[^0-9]/g,
                                 ""
                              );
                              setTelefono(telefono);
                           }}
                           required
                        />
                     </Col>
                     <Col xl="4" sm="12">
                        <Label className="col-form-label text-start">
                           Ciudad:
                        </Label>
                        <Input
                           value={ciudad}
                           id="ciudad"
                           type="text"
                           onChange={(e) => {
                              setCiudad(e.target.value);
                           }}
                        />
                     </Col>
                     <Col xl="4" sm="12">
                        <Label className="col-form-label text-start">
                           Desarrollo:
                        </Label>
                        <Select
                           value={desarrollo}
                           options={desarrollos}
                           placeholder={"Seleccione..."}
                           onChange={(desarr) => setDesarrollo(desarr)}
                           isDisabled={true}
                        />
                     </Col>
                     <Col xl="4" sm="12" style={{zIndex: 999}}>
                        <Label className="col-form-label text-start">
                           Interesados en:
                        </Label>
                        <Select
                           options={departamentos}
                           value={departamentosSelec}
                           onChange={(depts) => setDepartamentosSelec(depts)}
                           placeholder={"Seleccione..."}
                           isLoading={loadingDepartamentos}
                           // isClearable
                        />
                     </Col>
                     <Col xl="4" sm="12" style={{zIndex: 998}}>
                        <Label className="col-form-label text-start">
                           Calificación:
                        </Label>
                        <Select
                           value={calificacion}
                           options={calificaciones}
                           placeholder={"Seleccione..."}
                           onChange={(calificacion) =>
                              setCalificacion(calificacion)
                           }
                           required
                        />
                     </Col>
                     <Col xl="4" sm="12" style={{zIndex: 997}}>
                        <Label className="col-form-label text-start">
                           Fuente de contacto:
                        </Label>
                        <Select
                           value={fuente}
                           options={fuentes}
                           placeholder={"Seleccione..."}
                           onChange={(fuente) => setFuente(fuente)}
                           required
                        />
                     </Col>
                     <Col xl="4" sm="12" style={{zIndex: 996}}>
                        <Label className="col-form-label text-start">
                           Etapa:
                        </Label>
                        <Select
                           value={etapa}
                           options={etapas}
                           placeholder={"Seleccione..."}
                           onChange={(etapa) => setEtapa(etapa)}
                           required
                        />
                     </Col>
                     {etapa && etapa.label === "Seguimiento" && (
                        <Col xl="4" sm="12" style={{zIndex: 995}}>
                           <Label className="col-form-label text-start">
                              Posponer hasta:
                           </Label>
                           <Select
                              value={posponer}
                              options={posponerOpciones}
                              placeholder={"Seleccione..."}
                              onChange={(posponer) => setPosponer(posponer)}
                              // required
                           />
                        </Col>
                     )}
                  </Row>
               </FormGroup>
               <Form>
                  <Row>
                     <Col sm="6">
                        <div className="checkbox">
                           <Input
                              id="email_recuperacion1"
                              type="checkbox"
                              checked={
                                 emailRecuperacion1_fecha !== null
                                    ? true
                                    : false
                              }
                              readOnly
                           />
                           <Label
                              className="text-muted"
                              for="email_recuperacion1"
                           >
                              1er Correo de Recuperación
                           </Label>
                        </div>
                     </Col>
                     <Col sm="6">
                        {emailRecuperacion1_fecha != null && (
                           <Label className="text-muted p-10 font-success">
                              Enviado el día{" "}
                              {moment(emailRecuperacion1_fecha).format(
                                 "DD-MM-YYYY"
                              )}
                           </Label>
                        )}
                     </Col>
                  </Row>
                  <Row>
                     <Col sm="6">
                        <div className="checkbox">
                           <Input
                              id="email_recuperacion2"
                              type="checkbox"
                              checked={
                                 emailRecuperacion2_fecha !== null
                                    ? true
                                    : false
                              }
                              readOnly
                           />
                           <Label
                              className="text-muted"
                              for="email_recuperacion2"
                           >
                              2do Correo de Recuperación
                           </Label>
                        </div>
                     </Col>
                     <Col sm="6">
                        {emailRecuperacion2_fecha != null && (
                           <Label className="text-muted p-10 font-success">
                              Enviado el día{" "}
                              {moment(emailRecuperacion2_fecha).format(
                                 "DD-MM-YYYY"
                              )}
                           </Label>
                        )}
                     </Col>
                  </Row>
                  <Row>
                     <Col sm="6">
                        <div className="checkbox">
                           <Input
                              id="email_recuperacion3"
                              type="checkbox"
                              checked={
                                 emailRecuperacion3_fecha !== null
                                    ? true
                                    : false
                              }
                              readOnly
                           />
                           <Label
                              className="text-muted"
                              for="email_recuperacion3"
                           >
                              3er Correo de Recuperación
                           </Label>
                        </div>
                     </Col>
                     <Col sm="6">
                        {emailRecuperacion3_fecha != null && (
                           <Label className="text-muted p-10 font-success">
                              Enviado el día{" "}
                              {moment(emailRecuperacion3_fecha).format(
                                 "DD-MM-YYYY"
                              )}
                           </Label>
                        )}
                     </Col>
                  </Row>
                  {/* <Col xl="12" sm="12">
                     <div className="checkbox">
                        <Input
                           id="broker"
                           type="checkbox"
                           checked={broker}
                           onChange={(e) => setBroker(e.target.checked)}
                        />
                        <Label className="text-muted" for="broker">
                           Este prospecto es un BROKER
                        </Label>
                     </div>
                  </Col> */}
               </Form>
               <div>
                  <Col sm="12">
                     <Label className="col-form-label text-start">
                        Comentarios:
                     </Label>
                     <Input
                        value={comentarios}
                        id="comentarios"
                        type="textarea"
                        className="form-control"
                        rows="2"
                        onChange={(e) => {
                           setComentarios(e.target.value);
                        }}
                     />
                  </Col>
               </div>
            </div>
         </Row>
         <div className="text-end btn-showcase" style={{marginBottom: "-30px"}}>
            <Btn
               attrBtn={{
                  color: "secondary",
                  onClick: toggle,
                  className: "m-r-10",
               }}
            >
               Cancelar
            </Btn>
            <Btn
               attrBtn={{
                  type: "submit",
                  color: "primary",
                  disabled: isSubmitDisabled,
               }}
            >
               {isSubmitDisabled ? "Guardando..." : "Guardar"}
            </Btn>
         </div>
      </Form>
   );
};

export default ProspectEditForm;
