import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Col, Container, Form, FormGroup, Input, Row, Media} from "reactstrap";
import {Btn, H4, P} from "../../../AbstractElements";
import Logo from "../../../assets/images/login/logo-login.png";
import {toast} from "react-toastify";
import Request from "../../../api/httpClient";
const request = new Request();

const ForgetPwd = ({logoClassMain}) => {
   const [username, setUserName] = useState("");
   const [loading, setLoading] = useState(false);
   const [sendEmail, setSendEmail] = useState(false);

   const sendUser = async (e) => {
      e.preventDefault();
      if (username !== "") {
         setLoading(true);

         let data = {email: username};

         const response = await request.post(
            "/usuarios/usuario/recover/password",
            data
         );
         console.log(response);
         if (response && !response.error) {
            setSendEmail(true);
            toast.success(
               "Se le ha enviado un correo electrónico con las instrucciones para el cambio de contraseña.",
               {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 6000,
               }
            );
         } else {
            toast.error(response.message, {
               position: toast.POSITION.BOTTOM_RIGHT,
               autoClose: 2000,
            });
         }
         setLoading(false);
      }
   };

   return (
      <section>
         <Container className="p-0 login-page" fluid={true}>
            <Row className="m-0">
               <Col className="p-0">
                  <div className="login-card">
                     <div className="login-main">
                        <Link
                           className={`logo ${
                              logoClassMain ? logoClassMain : ""
                           }`}
                           to={process.env.PUBLIC_URL}
                        >
                           <Media src={Logo} alt="" style={{width: "25%"}} />
                        </Link>
                        <Form
                           className="theme-form login-form text-light"
                           onSubmit={sendUser}
                        >
                           <H4>Restablecer contraseña</H4>
                           <FormGroup>
                              <Input
                                 className="form-control"
                                 type="text"
                                 required
                                 onChange={(e) => setUserName(e.target.value)}
                                 value={username}
                                 disabled={sendEmail}
                                 placeholder="Email"
                              />
                           </FormGroup>
                           {!sendEmail && (
                              <FormGroup className="text-end">
                                 <Btn
                                    attrBtn={{
                                       className: "btn-block ",
                                       color: "primary",
                                       type: "submit",
                                       disabled: loading,
                                    }}
                                 >
                                    {loading ? "Enviando..." : "Enviar"}
                                 </Btn>
                              </FormGroup>
                           )}
                           {sendEmail && (
                              <FormGroup className="mb-4 mt-4">
                                 <span className="reset-password-link">
                                    ¿No ha recibido el correo?  
                                    <a
                                       className="btn-link text-danger"
                                       href="#resendEmail"
                                       onClick={(e) => sendUser(e)}
                                    >
                                       Reenviar
                                    </a>
                                 </span>
                              </FormGroup>
                           )}
                           <P attrPara={{className: "text-start mt-4"}}>
                              ¿Ya tienes una contraseña?
                              <a className="ms-2" href="/login">
                                 Iniciar sesión
                              </a>
                           </P>
                        </Form>
                     </div>
                  </div>
               </Col>
            </Row>
         </Container>
      </section>
   );
};

export default ForgetPwd;
