import React, {Fragment, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {
   Container,
   Row,
   Col,
   Card,
   CardBody,
   FormGroup,
   Input,
   Label,
} from "reactstrap";
import Swal from "sweetalert2";
import {Breadcrumbs, Btn} from "../../AbstractElements";
import Request from "../../api/httpClient";
import HeaderCard from "../Common/Component/HeaderCard";

const request = new Request();

const RecuperacionPage = () => {
   const [recuperacion, setRecuperacion] = useState([]);
   const [modifiedIndex, setModifiedIndex] = useState(null);

   useEffect(() => {
      getRecuperacion();
   }, []);

   const getRecuperacion = async () => {
      setRecuperacion([]);
      const response = await request.get("/recuperacion/get/all");
      if (response && !response.error) {
         if (response && !response.empty) {
            setRecuperacion(response);
         } else {
            setRecuperacion([]);
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }
   };

   const handleEditarRecuperacionAsunto = () => {
      Swal.fire({
         title: "Está seguro de editar este asunto?",
         text:
            "Se editará el asunto: " + recuperacion[modifiedIndex].asuntoCorreo,
         icon: "info",
         showCancelButton: true,
         confirmButtonColor: "#3085d6",
         cancelButtonColor: "#d33",
         confirmButtonText: "Si, Editar",
         cancelButtonText: "Cancelar",
         reverseButtons: true,
      }).then(async (result) => {
         if (result.isConfirmed) {
            let data = {
               id_correo: recuperacion[modifiedIndex].idCorreo,
               asunto_correo: recuperacion[modifiedIndex].asuntoCorreo,
            };

            const response = await request.post(
               "/recuperacion/asunto/update",
               data
            );
            if (response && !response.error) {
               Swal.fire("Editado!", "Asunto editado con éxito", "success");
               getRecuperacion();
            } else {
               toast.error(response.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 2000,
               });
            }
         }
      });
   };

   const handleInputChange = (e, index) => {
      const {value} = e.target;
      setRecuperacion((prevRecuperacion) => {
         const newRecuperacion = [...prevRecuperacion];
         newRecuperacion[index].asuntoCorreo = value;
         return newRecuperacion;
      });
      setModifiedIndex(index);
   };

   return (
      <Fragment>
         <Breadcrumbs
            parent="Gestión"
            title="Recuperación"
            mainTitle="Recuperación"
         />
         <Container fluid={true}>
            <Row>
               <Col xl="6" className="box-col-6">
                  <Card>
                     <HeaderCard title="Asuntos - Correos de recuperación" />
                     <CardBody>
                        {recuperacion.map((rc, index) => (
                           <FormGroup className="row">
                              <Col md="12">
                                 <Label className="col-form-label text-start fw-semibold">
                                    Recuperación {index + 1}:
                                 </Label>
                              </Col>
                              <Col xs="10" md="10">
                                 <Input
                                    value={rc.asuntoCorreo}
                                    id={index}
                                    type="textarea"
                                    className="form-control"
                                    rows="2"
                                    onChange={(e) =>
                                       handleInputChange(e, index)
                                    }
                                 />
                              </Col>
                              <Col xs="2" md="2">
                                 {modifiedIndex === index && (
                                    <Btn
                                       attrBtn={{
                                          color: "success",
                                          style: {
                                             padding: "5px 10px",
                                             marginTop: "3px",
                                             marginLeft: "10px",
                                          },
                                          onClick: () => {
                                             handleEditarRecuperacionAsunto();
                                             setModifiedIndex(null);
                                          },
                                       }}
                                    >
                                       <i className="fa fa-check"></i>
                                    </Btn>
                                 )}
                              </Col>
                           </FormGroup>
                        ))}
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </Container>
      </Fragment>
   );
};

export default RecuperacionPage;
