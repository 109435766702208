import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";
import {Image} from "../../AbstractElements";
import LogoIcon from "../../assets/images/login/logo-login.png";
import CustomizerContext from "../../_helper/Customizer";

const SidebarLogo = () => {
   const {mixLayout, toggleSidebar, layout, layoutURL} =
      useContext(CustomizerContext);
   const [toggle, setToggle] = useState(false);

   const openCloseSidebar = () => {
      setToggle(!toggle);
      toggleSidebar(toggle);
   };

   const layout1 = localStorage.getItem("sidebar_layout") || layout;

   return (
      <div
         className="logo-wrapper"
         style={{height: "80px", textAlign: "center", paddingBottom: "14px"}}
      >
         {layout1 !== "compact-wrapper dark-sidebar" &&
         layout1 !== "compact-wrapper color-sidebar" &&
         mixLayout ? (
            <Link to={`/dashboard/default/${layoutURL}`}>
               <Image
                  attrImage={{
                     className: "img-fluid d-inline",
                     src: `${LogoIcon}`,
                     alt: "",
                     style: {
                        width: "50%",
                     },
                  }}
               />
            </Link>
         ) : (
            <Link to={`/dashboard/default/${layoutURL}`}>
               <Image
                  attrImage={{
                     className: "img-fluid d-inline",
                     src: `${LogoIcon}`,
                     alt: "",
                     style: {
                        width: "50%",
                     },
                  }}
               />
            </Link>
         )}
         <div className="back-btn" onClick={() => openCloseSidebar()}>
            <i className="fa fa-angle-left"></i>
         </div>
      </div>
   );
};

export default SidebarLogo;
