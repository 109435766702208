import React, {Fragment, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {
   Container,
   Row,
   Col,
   Card,
   CardBody,
   FormGroup,
   Input,
} from "reactstrap";
import Swal from "sweetalert2";
import {Breadcrumbs, Btn} from "../../AbstractElements";
import Request from "../../api/httpClient";
import HeaderCard from "../Common/Component/HeaderCard";

const request = new Request();

const CanalesPage = () => {
   const [canales, setCanales] = useState([]);
   const [modifiedIndex, setModifiedIndex] = useState(null);

   const [nombre, setNombre] = useState("");
   const [colorFondo, setColorFondo] = useState("#000000");
   const [colorTexto, setcolorTexto] = useState("#ffffff");

   useEffect(() => {
      getCanales();
   }, []);

   const getCanales = async () => {
      setCanales([]);
      const response = await request.get("/canales/get/all");
      if (response && !response.error) {
         if (response && !response.empty) {
            setCanales(response);
         } else {
            setCanales([]);
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }
   };

   const reload = (message) => {
      setNombre("");
      setColorFondo("#000000");
      setcolorTexto("#ffffff");

      toast.success(message, {
         position: toast.POSITION.BOTTOM_RIGHT,
         autoClose: 2000,
      });
      getCanales();
   };

   const handleEliminarCanal = (row) => {
      Swal.fire({
         title: "Está seguro de eliminar este canal?",
         text: "Se eliminará el canal: " + row.channelName,
         icon: "warning",
         showCancelButton: true,
         confirmButtonColor: "#3085d6",
         cancelButtonColor: "#d33",
         confirmButtonText: "Si, Eliminar",
         cancelButtonText: "Cancelar",
         reverseButtons: true,
      }).then(async (result) => {
         if (result.isConfirmed) {
            let data = {
               id_canal: row.channelId,
            };

            const response = await request.post("/canales/canal/delete", data);
            if (response && !response.error) {
               Swal.fire(
                  "Eliminado!",
                  "Este canal ha sido eliminado",
                  "success"
               );
               reload();
            } else {
               toast.error(response.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 2000,
               });
            }
         }
      });
   };

   const handleInputChangeNombre = (e, index) => {
      const {value} = e.target;
      setCanales((prevCanales) => {
         const newCanales = [...prevCanales];
         newCanales[index].channelName = value;
         return newCanales;
      });
      setModifiedIndex(index);
   };

   const handleInputChangeColorFondo = (e, index) => {
      const {value} = e.target;
      setCanales((prevCanales) => {
         const newCanales = [...prevCanales];
         newCanales[index].channelColor = value;
         return newCanales;
      });
      setModifiedIndex(index);
   };

   const handleInputChangeColorTexto = (e, index) => {
      const {value} = e.target;
      setCanales((prevCanales) => {
         const newCanales = [...prevCanales];
         newCanales[index].channelColorText = value;
         return newCanales;
      });
      setModifiedIndex(index);
   };

   const handleNuevoCanal = () => {
      Swal.fire({
         title: "Está seguro de crear un nuevo canal?",
         text: "Se creará el motivo: " + nombre,
         icon: "info",
         showCancelButton: true,
         confirmButtonColor: "#3085d6",
         cancelButtonColor: "#d33",
         confirmButtonText: "Si, Crear",
         cancelButtonText: "Cancelar",
         reverseButtons: true,
      }).then(async (result) => {
         if (result.isConfirmed) {
            let data = {
               nombre_canal: nombre,
               colorFondo_canal: colorFondo,
               colorTexto_canal: colorTexto,
            };

            const response = await request.post("/canales/canal/create", data);
            if (response && !response.error) {
               Swal.fire("Creado!", "Se ha creado un nuevo canal", "success");
               reload();
            } else {
               toast.error(response.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 2000,
               });
            }
         }
      });
   };

   const handleEditarCanal = () => {
      Swal.fire({
         title: "Está seguro de editar este canal?",
         text: "Se editará el canal: " + canales[modifiedIndex].channelName,
         icon: "info",
         showCancelButton: true,
         confirmButtonColor: "#3085d6",
         cancelButtonColor: "#d33",
         confirmButtonText: "Si, Editar",
         cancelButtonText: "Cancelar",
         reverseButtons: true,
      }).then(async (result) => {
         if (result.isConfirmed) {
            let data = {
               id_canal: canales[modifiedIndex].channelId,
               nombre_canal: canales[modifiedIndex].channelName,
               colorFondo_canal: canales[modifiedIndex].channelColor,
               colorTexto_canal: canales[modifiedIndex].channelColorText,
            };

            const response = await request.post("/canales/canal/update", data);
            if (response && !response.error) {
               Swal.fire("Editado!", "Canal editado con éxito", "success");
               reload();
            } else {
               toast.error(response.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 2000,
               });
            }
         }
      });
   };

   return (
      <Fragment>
         <Breadcrumbs parent="Gestión" title="Canales" mainTitle="Canales" />
         <Container fluid={true}>
            <Row>
               <Col xl="6" className="box-col-6">
                  <Card>
                     <HeaderCard title="Canales" />
                     <CardBody>
                        {canales.map((canal, index) => (
                           <FormGroup className="row">
                              <Col xs="6" md="6">
                                 <Input
                                    value={canal.channelName}
                                    id={index}
                                    type="text"
                                    onChange={(e) =>
                                       handleInputChangeNombre(e, index)
                                    }
                                 />
                              </Col>
                              <Col
                                 xs="3"
                                 md="3"
                                 style={{
                                    display: "flex",
                                 }}
                              >
                                 <Input
                                    className="form-control-color m-l-10"
                                    type="color"
                                    defaultValue="#000000"
                                    value={canal.channelColor}
                                    onChange={(e) =>
                                       handleInputChangeColorFondo(e, index)
                                    }
                                 />

                                 <Input
                                    className="form-control-color m-l-10"
                                    type="color"
                                    defaultValue="#ffffff"
                                    value={canal.channelColorText}
                                    onChange={(e) =>
                                       handleInputChangeColorTexto(e, index)
                                    }
                                 />
                              </Col>
                              <Col
                                 xs="3"
                                 md="3"
                                 style={{
                                    display: "flex",
                                 }}
                              >
                                 <Btn
                                    attrBtn={{
                                       color: "secondary",
                                       style: {
                                          padding: "5px 10px",
                                          marginTop: "3px",
                                          marginLeft: "10px",
                                          width: "3rem",
                                       },
                                       onClick: () => {
                                          handleEliminarCanal(canal);
                                       },
                                    }}
                                 >
                                    <i className="fa fa-trash"></i>
                                 </Btn>
                                 {modifiedIndex === index && (
                                    <Btn
                                       attrBtn={{
                                          color: "success",
                                          style: {
                                             padding: "5px 10px",
                                             marginTop: "3px",
                                             marginLeft: "10px",
                                             width: "3rem",
                                          },
                                          onClick: () => {
                                             handleEditarCanal();
                                             setModifiedIndex(null);
                                          },
                                       }}
                                    >
                                       <i className="fa fa-check"></i>
                                    </Btn>
                                 )}
                              </Col>
                           </FormGroup>
                        ))}
                        <FormGroup className="row">
                           <Col xs="6" md="6">
                              <Input
                                 id="nuevo"
                                 type="text"
                                 value={nombre}
                                 placeholder="Nuevo canal"
                                 onChange={(e) => {
                                    setNombre(e.target.value);
                                 }}
                              />
                           </Col>
                           <Col
                              xs="3"
                              md="3"
                              style={{
                                 display: "flex",
                              }}
                           >
                              {" "}
                              <Input
                                 className="form-control-color m-l-10"
                                 type="color"
                                 defaultValue="#000000"
                                 value={colorFondo}
                                 onChange={(e) => {
                                    setColorFondo(e.target.value);
                                 }}
                              />
                              <Input
                                 className="form-control-color m-l-10"
                                 type="color"
                                 defaultValue="#ffffff"
                                 value={colorTexto}
                                 onChange={(e) => {
                                    setcolorTexto(e.target.value);
                                 }}
                              />
                           </Col>
                           <Col
                              xs="3"
                              md="3"
                              style={{
                                 display: "flex",
                              }}
                           >
                              <Btn
                                 attrBtn={{
                                    color: "info",
                                    style: {
                                       padding: "5px 10px",
                                       marginTop: "3px",
                                       marginLeft: "10px",
                                       width: "3rem",
                                    },
                                    onClick: () => {
                                       handleNuevoCanal();
                                    },
                                 }}
                              >
                                 <i className="fa fa-plus"></i>
                              </Btn>
                           </Col>
                        </FormGroup>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </Container>
      </Fragment>
   );
};

export default CanalesPage;
